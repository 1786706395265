import { Currency, Pair } from '@uniswap/sdk'
import { Text } from 'rebass'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { RowBetween, AutoRow } from '../Row'
import { AutoColumn } from '../Column'
import { TYPE } from '../../theme'
import { useActiveWeb3React } from '../../hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import TradePrice from '../../components/swap/TradePrice'

import useTheme from '../../hooks/useTheme'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import PIInputConfirm from '../Modal/PIInputConfirm'
import { InputInt as NumericalInput } from '../NumericalInput'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'

import { Select, Divider, Space, InputNumber } from 'antd'
import { Field } from 'state/swap/actions'
import QuestionHelper from '../QuestionHelper'
const { Option } = Select

const StyledInputNumber = styled(InputNumber)`
  .ant-select-selection-placeholder {
    padding-left: 0.2rem !important;
  }
`

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0rem 1rem' : '0.75rem 0.75rem 0rem 1rem')};
`

const ExpirePanel = styled.div`
  border-radius: 16px;
  // background-color: ${({ theme }) => theme.blue1};
  z-index: 1;
  margin-top: 4px;
  width: 100%;
  /* max-width: 400px;
  min-width: 380px; */
`

const HLayoutWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`

const ExpireContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.bg6};
  padding: 1rem 0rem 1rem 0;
  flex: 1;
  max-width: 240px;
`

const PriceWrapper = styled.div`
  margin-left: 20px;
  flex: 1;
  max-width: 360px;
`

const PriceTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const PriceImpactTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`
const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const TimeSelect = styled.div<{ selected: boolean }>`
  text-align: start;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.bg6 : theme.primary1)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border: none;
  margin: 12px 0.85rem 0px 0.85rem;

  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg2 : darken(0.05, theme.primary1))};
  }
`

const CustomTimeButton = styled.span`
  font-size: 16px;
  cursor: pointer;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const PriceText = styled.span`
  font-size: 14px;
`

const PriceTextDisable = styled(PriceText)`
  font-weight: 600;
  color: ${({ theme }) => theme.text4};
`

interface PriceProps {
  price: number
  amount: string
  disable?: boolean
}

// const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
//   currencies[Field.INPUT],
//   currencies[Field.OUTPUT],
//   typedValue
// )

// const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
// const trade = showWrap ? undefined : resTrade

const PriceContent: React.FC<PriceProps> = ({ price, amount, disable }) => {
  return (
    <Aligner>
      {disable ? <PriceTextDisable>not available</PriceTextDisable> : <PriceText>{'~' + price}</PriceText>}
      <PriceText style={{ marginRight: '.2rem' }}>{amount}</PriceText>
    </Aligner>
  )
}

interface Props {
  title: string
  onChange: any
  currency?: Currency | null
  buyCurrency?: Currency | null
  pair?: Pair | null
  priceDisable?: boolean
  onBulr: any
}

let customTimeName = ''

export const LongTermBlockInputPanel: React.FC<Props> = ({
  title,
  currency,
  buyCurrency,
  pair,
  onChange,
  onBulr,
  priceDisable = false
}) => {
  //    const titleName: string = title
  //    const stateValue: number = value
  //    const stateChangeHandler: any = onChange
  const [modalOpen, setModalOpen] = useState(false)
  const { independentField, typedValue, recipient, blockValue } = useSwapState()
  const [{ selectedButtonName }, setSelectedButtonState] = useState<{
    selectedButtonName: string
  }>({
    selectedButtonName: 'select time'
  })

  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    resTrade,
    marketPrice,
    inputError: swapInputError
  } = useDerivedSwapInfo()

  const [{ showInputConfirm }, setConfirmState] = useState<{
    showInputConfirm: boolean
  }>({
    showInputConfirm: false
  })

  const [showInverted, setShowInverted] = useState<boolean>(false)

  const [selectTimeItems, setSelectTimeItems] = useState([
    '10min',
    '30min',
    '1hour',
    '4hour',
    '12hour',
    '1day',
    '1week',
    '1month'
  ])
  const [name, setName] = useState('')

  const { account, chainId } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const theme = useTheme()

  const onTimeSelect = () => {
    console.log('onTimeSelect')
    console.log(pair, pair?.token0.symbol, currency)
  }
  const checkMantleBase = chainId === 5000 || chainId === 5001

  const onCustomTimeSelect = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // e.preventDefault()
    console.log('onCustomTimeSelect')

    setConfirmState({ showInputConfirm: true })

    // setSelectTimeItems([...selectTimeItems, name || `New item ${customTimeName}`])
    // setName('')
  }

  // const {
  //   v1Trade,
  //   v2Trade,
  //   currencyBalances,
  //   parsedAmount,
  //   currencies,
  //   resTrade,
  //   inputError: swapInputError
  // } = useDerivedSwapInfo()

  // const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
  //   currencies[Field.INPUT],
  //   currencies[Field.OUTPUT],
  //   typedValue
  // )

  // const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  // const trade = showWrap ? undefined : resTrade

  return (
    <ExpirePanel>
      <HLayoutWrapper>
        <ExpireContainer>
          <LabelRow>
            <RowBetween>
              <TYPE.body color={theme.text2} fontWeight={500} fontSize={14}>
                {title}
                <QuestionHelper
                  text={
                    'Expires in = 5 · orderInterval + 5 - currentBlockHeight % 5, e.g. if orderInterval = 100, currentBlockHeight = 52, so Expires in = 5 · 100 + 3 = 503'
                  }
                />
              </TYPE.body>
              {account && (
                <TYPE.body
                  color={theme.text2}
                  fontWeight={500}
                  fontSize={14}
                  style={{ display: 'inline', cursor: 'pointer' }}
                ></TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
          <InputRow style={{ padding: '0 1rem', paddingTop: '.2rem', borderRadius: '8px' }} selected={false}>
            <NumericalInput
              placeholder="1"
              value={blockValue || ''}
              onUserInput={onChange}
              onBlur={onBulr ?? undefined}
            />
          </InputRow>
        </ExpireContainer>

        <PriceWrapper>
          <AutoRow style={{ width: '100%' }}>
            <AutoColumn style={{ width: '100%' }}>
              {/* <PriceTitle>
                {currency
                  ? checkMantleBase
                    ? currency?.symbol === 'ETH'
                      ? 'MNT'
                      : currency?.symbol
                    : currency?.symbol
                  : '-'}{' '}
                Average Sell Price
              </PriceTitle> */}
              {/* <PriceContent price={1} amount={'' + (buyCurrency ? buyCurrency?.symbol : '-')}></PriceContent> */}
              {/* <PriceContent
                disable={priceDisable}
                price={1}
                amount={
                  '' +
                  (buyCurrency
                    ? checkMantleBase
                      ? buyCurrency?.symbol === 'ETH'
                        ? 'MNT'
                        : buyCurrency?.symbol
                      : currency?.symbol
                    : '-')
                }
              ></PriceContent> */}
              <RowBetween align="center">
                <Text fontWeight={500} fontSize={14} color={theme.text2}>
                  Market Price
                </Text>
                <TradePrice
                  inputToken={currencies[Field.INPUT]}
                  outputToken={currencies[Field.OUTPUT]}
                  price={resTrade?.marketPrice || marketPrice}
                  showInverted={showInverted}
                  setShowInverted={setShowInverted}
                />
              </RowBetween>
              <div style={{ height: '4px', width: '1px' }}></div>
              <RowBetween align="center">
                <Text fontWeight={500} fontSize={14} color={theme.text2}>
                  Average Price
                </Text>
                <Text fontWeight={500} fontSize={14} color={theme.text2}>
                  TBD
                </Text>
                {/* <TradePrice
                  inputToken={currencies[Field.INPUT]}
                  outputToken={currencies[Field.OUTPUT]}
                  price={resTrade?.averagePrice}
                  showInverted={showInverted}
                  setShowInverted={setShowInverted}
                /> */}
              </RowBetween>
            </AutoColumn>
            <div style={{ height: '4px', width: '1px' }}></div>
            <AutoColumn style={{ width: '100%' }}>
              <RowBetween align="center">
                <Text fontWeight={500} fontSize={14} color={theme.text2}>
                  Price Impact
                </Text>
                <Text fontWeight={500} fontSize={14} color={theme.text2}>
                  {resTrade?.PriceImpactBlock ?? '-'} {resTrade?.PriceImpactBlock && '%'}
                </Text>
              </RowBetween>
              {/* {Boolean(trade) && (
                <RowBetween align="center">
                  <PriceImpactTitle>Price Impact</PriceImpactTitle>
                  <div>
                    {trade?.PriceImpact ?? '-'} {trade?.PriceImpact && '%'}
                  </div>
                </RowBetween>
              )} */}
            </AutoColumn>
          </AutoRow>
        </PriceWrapper>
      </HLayoutWrapper>

      <PIInputConfirm
        isOpen={showInputConfirm}
        title="Setup Custom Period"
        onConfirm={data => {
          setConfirmState({ showInputConfirm: false })

          customTimeName = data.hour + 'hour' + data.minutes + 'min'
          // TODO: 时间格式优化
          setSelectTimeItems([...selectTimeItems, customTimeName])
        }}
        onDismiss={() => {
          setConfirmState({ showInputConfirm: false })
        }}
      />
    </ExpirePanel>
  )
}

export default LongTermBlockInputPanel
