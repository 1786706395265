import { JSBI, Pair, Percent, TokenAmount } from '@uniswap/sdk'
import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, TYPE } from '../../theme'
import { currencyId, useCurrencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary, ButtonSecondary, ButtonEmpty } from '../Button'
import { transparentize } from 'polished'
import { CardNoise } from '../earn/styled'

import { useColor } from '../../hooks/useColor'

import Card, { GreyCard, LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed, AutoRow } from '../Row'
import { Dots } from '../swap/styleds'
import { BIG_INT_ZERO } from '../../constants'
import {
  PairState,
  useExecuteVirtualOrdersInterface,
  usePair,
  useExecuteVirtualOrdersInterfaceV2,
  usePairAddress
} from '../../data/Reserves'
import { useSingleCallResult, useSingleContractMultipleData, Result } from '../../state/multicall/hooks'
import { getRouterContractShort, getPairContractConfirmed, getEtherscanLink } from '../../utils'
import { useBlockNumber } from '../../state/application/hooks'
import { BigNumber, utils } from 'ethers'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: ${({ theme, bgColor }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${transparentize(0.8, bgColor)} 0%, ${theme.bg3} 100%) `};
  position: relative;
  overflow: hidden;
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account, library } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const poolAddr = usePairAddress(pair.token0, pair.token1)[0]

  // let currentBlockNumber = useBlockNumber()

  // const [pairState, Pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)

  // let [
  //   reserveA,
  //   reserveB,
  //   lastVirtualOrderBlock,
  //   currentSalesRateA,
  //   currentSalesRateB,
  //   rewardFactorA,
  //   rewardFactorB
  // ] = useExecuteVirtualOrdersInterface([currentBlockNumber ?? 0], Pair, pairState === PairState.EXISTS)[0]

  // let reserve0
  // let reserve1

  // if (Pair && currentBlockNumber) {
  //   if (Pair.token0.address <= Pair.token1.address) {
  //     ;[reserve0, reserve1] = [reserveA, reserveB]
  //   } else {
  //     ;[reserve0, reserve1] = [reserveB, reserveA]
  //   }
  // } else {
  //   ;[reserve0, reserve1] = [undefined, undefined]
  // }
  let currentBlockNumber = useBlockNumber()

  // const [pairState, Pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)

  // let [
  //   reserveA,
  //   reserveB,
  //   lastVirtualOrderBlock,
  //   currentSalesRateA,
  //   currentSalesRateB,
  //   rewardFactorA,
  //   rewardFactorB
  // ] = useExecuteVirtualOrdersInterface([currentBlockNumber ?? 0], pair, !!pair)[0]

  const expiriesList = useSingleContractMultipleData(
    pair && library && account ? getPairContractConfirmed(pair.liquidityToken.address, library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    !!pair
  )
  const preprocessedExpiryList = expiriesList[0]?.result as any
  let reserveA: BigNumber
  let reserveB: BigNumber
  let lastVirtualOrderBlock: number
  let currentSalesRateA: BigNumber
  let currentSalesRateB: BigNumber
  let rewardFactorA: BigNumber
  let rewardFactorB: BigNumber
  ///////////////////////// below for old changes /////////////////////////////
  const stateInfoSinceLastExecutionMap = useExecuteVirtualOrdersInterfaceV2(
    !!preprocessedExpiryList ? preprocessedExpiryList[0].map((e: BigNumber) => e?.toNumber()) : [0],
    pair,
    !!pair
  )

  if (
    !!currentBlockNumber &&
    !!stateInfoSinceLastExecutionMap &&
    !Array.isArray(stateInfoSinceLastExecutionMap) &&
    !!stateInfoSinceLastExecutionMap[currentBlockNumber]
    // Array.isArray(stateInfoSinceLastExecutionMap[currentBlockNumber])
    // !!currentBlockNumber &&
    // !!preprocessedExpiryList &&
    // !!stateInfoSinceLastExecutionMap &&
    // Array.isArray(stateInfoSinceLastExecutionMap) &&
    // Array.isArray(stateInfoSinceLastExecutionMap[currentBlockNumber])
  ) {
    ;[
      reserveA,
      reserveB,
      lastVirtualOrderBlock,
      currentSalesRateA,
      currentSalesRateB,
      rewardFactorA,
      rewardFactorB
    ] = stateInfoSinceLastExecutionMap[currentBlockNumber]
  } else {
    ;[reserveA, reserveB, lastVirtualOrderBlock, currentSalesRateA, currentSalesRateB, rewardFactorA, rewardFactorB] = [
      BigNumber.from(0),
      BigNumber.from(0),
      0,
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0)
    ]
  }

  // let reserve0
  // let reserve1

  // if (Pair && currentBlockNumber) {
  //   if (Pair.token0.address <= Pair.token1.address) {
  //     ;[reserve0, reserve1] = [reserveA, reserveB]
  //   } else {
  //     ;[reserve0, reserve1] = [reserveB, reserveA]
  //   }
  // } else {
  //   ;[reserve0, reserve1] = [undefined, undefined]
  // }

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    !!reserveA &&
    !reserveA.eq(0) &&
    !!reserveB &&
    !reserveB.eq(0) &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          // pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          // pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
          // @ts-ignore
          pair.newGetLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, reserveA, reserveB, false),
          // @ts-ignore
          pair.newGetLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, reserveA, reserveB, false)
        ]
      : [undefined, undefined]

  const { chainId } = useActiveWeb3React()
  const checkMantleBase = chainId === 5000 || chainId === 5001
  const loading = !token0Deposited && !token1Deposited

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        <GreyCard border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={16}>
                  Your position
                </Text>
                {/* {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />} */}
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={500} fontSize={20}>
                  {checkMantleBase ? (currency0.symbol === 'ETH' ? 'MNT' : currency0.symbol) : currency0.symbol}/
                  {checkMantleBase ? (currency1.symbol === 'ETH' ? 'MNT' : currency1.symbol) : currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={20}>
                  {userPoolBalance ? userPoolBalance.toSignificant(6) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your pool share:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  {checkMantleBase ? (currency0.symbol === 'ETH' ? 'MNT' : currency0.symbol) : currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  <RowFixed>
                    {loading && (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />
                    )}
                  </RowFixed>
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  {checkMantleBase ? (currency1.symbol === 'ETH' ? 'MNT' : currency1.symbol) : currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  <RowFixed>
                    {loading && (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />
                    )}
                  </RowFixed>
                )}
              </FixedHeightRow>
            </AutoColumn>
            <ButtonSecondary padding="8px" borderRadius="8px">
              <ExternalLink
                style={{ width: '100%', textAlign: 'center' }}
                href={chainId ? getEtherscanLink(chainId, poolAddr, 'address') : ''}
              >
                View pool and analytics<span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </ButtonSecondary>
          </AutoColumn>
        </GreyCard>
      ) : (
        <LightCard>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '}
            By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the pool.
            Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </TYPE.subHeader>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border, stakedBalance }: PositionCardProps) {
  const { account, chainId, library } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const currencyId = useCurrencyId()

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  // const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance
  const userPoolBalance = userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const checkMantleBase = chainId === 5000 || chainId === 5001
  // const [pairState, Pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)
  // let currentBlockNumber = useBlockNumber()

  // const [pairState, Pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)

  // let [
  //   reserveA,
  //   reserveB,
  //   lastVirtualOrderBlock,
  //   currentSalesRateA,
  //   currentSalesRateB,
  //   rewardFactorA,
  //   rewardFactorB
  // ] = useExecuteVirtualOrdersInterface([currentBlockNumber ?? 0], Pair, pairState === PairState.EXISTS)[0]

  // let reserve0
  // let reserve1

  // if (Pair && currentBlockNumber) {
  //   if (Pair.token0.address <= Pair.token1.address) {
  //     ;[reserve0, reserve1] = [reserveA, reserveB]
  //   } else {
  //     ;[reserve0, reserve1] = [reserveB, reserveA]
  //   }
  // } else {
  //   ;[reserve0, reserve1] = [undefined, undefined]
  // }

  // const [token0Deposited, token1Deposited] =
  //   !!pair &&
  //   !!totalPoolTokens &&
  //   !!userPoolBalance &&
  //   // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
  //   JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
  //     ? [
  //         // pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
  //         // pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
  //         // @ts-ignore
  //         pair.newGetLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, reserveA, reserveB, false),
  //         // @ts-ignore
  //         pair.newGetLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, reserveA, reserveB, false)
  //       ]
  //     : [undefined, undefined]

  let currentBlockNumber = useBlockNumber()

  // const [pairState, Pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)

  // let [
  //   reserveA,
  //   reserveB,
  //   lastVirtualOrderBlock,
  //   currentSalesRateA,
  //   currentSalesRateB,
  //   rewardFactorA,
  //   rewardFactorB
  // ] = useExecuteVirtualOrdersInterface([currentBlockNumber ?? 0], pair, !!pair)[0]

  // let reserve0
  // let reserve1

  // if (Pair && currentBlockNumber) {
  //   if (Pair.token0.address <= Pair.token1.address) {
  //     ;[reserve0, reserve1] = [reserveA, reserveB]
  //   } else {
  //     ;[reserve0, reserve1] = [reserveB, reserveA]
  //   }
  // } else {
  //   ;[reserve0, reserve1] = [undefined, undefined]
  // }

  const expiriesList = useSingleContractMultipleData(
    pair && library && account ? getPairContractConfirmed(pair.liquidityToken.address, library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    !!pair
  )
  const preprocessedExpiryList = expiriesList[0]?.result as any
  let reserveA: BigNumber
  let reserveB: BigNumber
  let lastVirtualOrderBlock: number
  let currentSalesRateA: BigNumber
  let currentSalesRateB: BigNumber
  let rewardFactorA: BigNumber
  let rewardFactorB: BigNumber
  ///////////////////////// below for old changes /////////////////////////////
  const stateInfoSinceLastExecutionMap = useExecuteVirtualOrdersInterfaceV2(
    !!preprocessedExpiryList ? preprocessedExpiryList[0].map((e: BigNumber) => e?.toNumber()) : [0],
    pair,
    !!pair
  )
  if (
    !!currentBlockNumber &&
    !!stateInfoSinceLastExecutionMap &&
    !Array.isArray(stateInfoSinceLastExecutionMap) &&
    Array.isArray(stateInfoSinceLastExecutionMap[currentBlockNumber])
    // !!currentBlockNumber &&
    // !!preprocessedExpiryList &&
    // !!stateInfoSinceLastExecutionMap &&
    // Array.isArray(stateInfoSinceLastExecutionMap) &&
    // Array.isArray(stateInfoSinceLastExecutionMap[currentBlockNumber])
  ) {
    ;[
      reserveA,
      reserveB,
      lastVirtualOrderBlock,
      currentSalesRateA,
      currentSalesRateB,
      rewardFactorA,
      rewardFactorB
    ] = stateInfoSinceLastExecutionMap[currentBlockNumber]
  } else {
    ;[reserveA, reserveB, lastVirtualOrderBlock, currentSalesRateA, currentSalesRateB, rewardFactorA, rewardFactorB] = [
      BigNumber.from(0),
      BigNumber.from(0),
      0,
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0)
    ]
  }

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    !!reserveA &&
    !reserveA.eq(0) &&
    !!reserveB &&
    !reserveB.eq(0) &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          // pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          // pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
          // @ts-ignore
          pair.newGetLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, reserveA, reserveB, false),
          // @ts-ignore
          pair.newGetLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, reserveA, reserveB, false)
        ]
      : [undefined, undefined]

  const backgroundColor = useColor(pair?.token0)

  const poolAddr = usePairAddress(pair.token0, pair.token1)[0]

  const loading = showMore && !token0Deposited && !token1Deposited

  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <CardNoise />
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <AutoRow gap="8px">
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20} />
            <Text fontWeight={500} fontSize={20}>
              {!currency0 || !currency1 ? (
                <Dots>Loading</Dots>
              ) : (
                `${checkMantleBase ? (currency0.symbol === 'ETH' ? 'MNT' : currency0.symbol) : currency0.symbol}/${
                  checkMantleBase ? (currency1.symbol === 'ETH' ? 'MNT' : currency1.symbol) : currency1.symbol
                }`
              )}
            </Text>
            {/* {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />} */}
          </AutoRow>
          <RowFixed gap="8px">
            <ButtonEmpty
              padding="6px 8px"
              borderRadius="12px"
              width="fit-content"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                <>
                  Manage
                  <ChevronUp size="20" style={{ marginLeft: '10px' }} />
                </>
              ) : (
                <>
                  Manage
                  <ChevronDown size="20" style={{ marginLeft: '10px' }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your total pool tokens:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {userPoolBalance ? userPoolBalance.toSignificant(6) : '-'}
              </Text>
            </FixedHeightRow>
            {stakedBalance && (
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Pool tokens in rewards pool:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {stakedBalance.toSignificant(4)}
                </Text>
              </FixedHeightRow>
            )}
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={500}>
                  Pooled {checkMantleBase ? (currency0.symbol === 'ETH' ? 'MNT' : currency0.symbol) : currency0.symbol}:
                </Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {token0Deposited?.toSignificant(6)}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                <RowFixed>
                  {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />}
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={500}>
                  Pooled {checkMantleBase ? (currency1.symbol === 'ETH' ? 'MNT' : currency1.symbol) : currency1.symbol}:
                </Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {token1Deposited?.toSignificant(6)}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                <RowFixed>
                  {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />}
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your pool share:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {poolTokenPercentage
                  ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
                  : '-'}
              </Text>
            </FixedHeightRow>

            {/* <ButtonSecondary padding="8px" borderRadius="8px">
              <ExternalLink
                style={{ width: '100%', textAlign: 'center' }}
                href={`https://info.pulsarswap.com/account/${account}`}
              >
                View accrued fees and analytics<span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </ButtonSecondary> */}
            <ButtonSecondary padding="8px" borderRadius="8px">
              <ExternalLink
                style={{ width: '100%', textAlign: 'center' }}
                href={chainId ? getEtherscanLink(chainId, poolAddr, 'address') : ''}
              >
                View pool and analytics<span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </ButtonSecondary>
            {userDefaultPoolBalance && (
              <RowBetween marginTop="10px">
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  as={Link}
                  to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                  width="48%"
                >
                  Add
                </ButtonPrimary>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  as={Link}
                  width="48%"
                  to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
                >
                  Remove
                </ButtonPrimary>
              </RowBetween>
            )}
            {stakedBalance && JSBI.greaterThan(stakedBalance.raw, BIG_INT_ZERO) && (
              <ButtonPrimary
                padding="8px"
                borderRadius="8px"
                as={Link}
                to={`/pul/${currencyId(currency0)}/${currencyId(currency1)}`}
                width="100%"
              >
                Manage Liquidity in Rewards Pool
              </ButtonPrimary>
            )}
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}
