import { Web3ReactProvider } from '@web3-react/core'
import { getAddress } from '@ethersproject/address'
import { PUL } from './../../constants/index'
import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount, Pair } from '@uniswap/sdk'
import { useMemo } from 'react'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import TWAMM from '../../constants/abis/TWAMM'
import { useAllTokens } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useMulticallContract } from '../../hooks/useContract'
import { isAddress, getRouterContractShort, getRouterContract } from '../../utils'
import { useSingleContractMultipleData, useMultipleContractSingleData } from '../multicall/hooks'
import { useUserUnclaimedAmount } from '../claim/hooks'
import { useTotalPulEarned } from '../stake/hooks'
import { usePairAddress } from '../../data/Reserves'
import { BIPS_BASE, INITIAL_ALLOWED_SLIPPAGE, TWAMM_ADDRESS, useTWAMM_ADDRESS } from '../../constants'

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses?.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens]
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map(vt => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(() => {
      return address && validatedTokens.length > 0
        ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
            const value = balances?.[i]?.result?.[0]
            const amount = value ? JSBI.BigInt(value.toString()) : undefined
            if (amount) {
              memo[token.address] = new TokenAmount(token, amount)
            }
            return memo
          }, {})
        : {}
    }, [address, validatedTokens, balances]),
    anyLoading
  ]
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function usePairBalancesWithLoadingIndicator(
  address?: string,
  // tokens?: (Token | undefined | any)[]
  tokens?: {
    liquidityToken: Token
    tokens: [Token, Token]
  }[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () =>
      tokens
        ?.filter(({ liquidityToken }) => isAddress(liquidityToken?.address) !== false)
        .map(({ liquidityToken }) => liquidityToken) ?? [],
    [tokens]
  )
  const { chainId, library, account } = useActiveWeb3React()

  const twoTokens: [Token, Token][] = useMemo(
    () => tokens?.map(e => e?.tokens).filter(e => typeof e !== 'undefined') ?? [],
    [tokens]
  )

  // const validatedTokenAddresses = useMemo(
  //   () =>
  //     validatedTokens.map(
  //       vt => TWAMM_ADDRESS
  //       // vt.address
  //     ),
  //   [validatedTokens]
  // )

  const inputAddrs = useMemo(() => twoTokens.filter(e => e.length === 2).map(vt => [vt[0].address, vt[1].address]), [
    twoTokens
  ])

  // const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])
  const balances = useSingleContractMultipleData(
    chainId && library && account ? getRouterContract(chainId, library, account) : undefined,
    'obtainTotalSupply',
    inputAddrs,
    undefined,
    inputAddrs.length !== 0
  )

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(() => {
      return address && validatedTokens.length > 0
        ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
            const value = balances?.[i]?.result?.[0]
            const amount = value ? JSBI.BigInt(value.toString()) : undefined
            if (amount) {
              memo[token.address] = new TokenAmount(token, amount)
            }
            return memo
          }, {})
        : {}
    }, [address, validatedTokens, balances]),
    anyLoading
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [
    currencies
  ])

  const tokenBalances = useTokenBalances(account, tokens)
  const containsETH: boolean = useMemo(() => currencies?.some(currency => currency === ETHER) ?? false, [currencies])
  const ethBalance = useETHBalances(containsETH ? [account] : [])

  return useMemo(
    () =>
      currencies?.map(currency => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) return tokenBalances[currency.address]
        if (currency === ETHER) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}

// get the total owned, unclaimed, and unharvested PUL for account
export function useAggregatePulBalance(): TokenAmount | undefined {
  const { account, chainId } = useActiveWeb3React()

  const pul = chainId ? PUL[chainId] : undefined

  const pulBalance: TokenAmount | undefined = useTokenBalance(account ?? undefined, pul)
  const pulUnclaimed: TokenAmount | undefined = useUserUnclaimedAmount(account)
  const pulUnHarvested: TokenAmount | undefined = useTotalPulEarned()

  if (!pul) return undefined

  return new TokenAmount(
    pul,
    JSBI.add(
      JSBI.add(pulBalance?.raw ?? JSBI.BigInt(0), pulUnclaimed?.raw ?? JSBI.BigInt(0)),
      pulUnHarvested?.raw ?? JSBI.BigInt(0)
    )
  )
}
