import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch, AppState } from '../index'
import { addPopup, ApplicationModal, PopupContent, removePopup, setOpenModal } from './actions'

// const MISSING_PROVIDER = Symbol()
// const BlockNumberContext = createContext<
//   | {
//       value?: number
//       fastForward(block: number): void
//     }
//   | typeof MISSING_PROVIDER
// >(MISSING_PROVIDER)

// function useBlockNumberContext() {
//   const blockNumber = useContext(BlockNumberContext)
//   if (blockNumber === MISSING_PROVIDER) {
//     throw new Error('BlockNumber hooks must be wrapped in a <BlockNumberProvider>')
//   }
//   return blockNumber
// }

// /** Requires that BlockUpdater be installed in the DOM tree. */
// export default function useBlockNumber(): number | undefined {
//   return useBlockNumberContext().value
// }

// export function useFastForwardBlockNumber(): (block: number) => void {
//   return useBlockNumberContext().fastForward
// }

// export function BlockNumberProvider({ children }: { children: ReactNode }) {
//   const { chainId: activeChainId, provider } = useWeb3React()
//   const [{ chainId, block }, setChainBlock] = useState<{ chainId?: number; block?: number }>({ chainId: activeChainId })

//   const onBlock = useCallback(
//     (block: number) => {
//       setChainBlock(chainBlock => {
//         if (chainBlock.chainId === activeChainId) {
//           if (!chainBlock.block || chainBlock.block < block) {
//             return { chainId: activeChainId, block }
//           }
//         }
//         return chainBlock
//       })
//     },
//     [activeChainId, setChainBlock]
//   )

//   const windowVisible = useIsWindowVisible()
//   useEffect(() => {
//     let stale = false

//     if (provider && activeChainId && windowVisible) {
//       // If chainId hasn't changed, don't clear the block. This prevents re-fetching still valid data.
//       setChainBlock(chainBlock => (chainBlock.chainId === activeChainId ? chainBlock : { chainId: activeChainId }))

//       provider
//         .getBlockNumber()
//         .then((block: number) => {
//           if (!stale) onBlock(block)
//         })
//         .catch((error: any) => {
//           console.error(`Failed to get block number for chainId ${activeChainId}`, error)
//         })

//       provider.on('block', onBlock)
//       return () => {
//         stale = true
//         provider.removeListener('block', onBlock)
//       }
//     }

//     return void 0
//   }, [activeChainId, provider, onBlock, setChainBlock, windowVisible])

//   const value = useMemo(
//     () => ({
//       value: chainId === activeChainId ? block : undefined,
//       fastForward: (update: number) => {
//         if (block && update > block) {
//           setChainBlock({ chainId: activeChainId, block: update })
//         }
//       }
//     }),
//     [activeChainId, block, chainId]
//   )
//   return <BlockNumberContext.Provider value={value}>{children}</BlockNumberContext.Provider>
// }

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal)
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useOpenModal(modal: ApplicationModal): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal])
}

export function useCloseModals(): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function useToggleSettingsMenu(): () => void {
  return useToggleModal(ApplicationModal.SETTINGS)
}

export function useShowClaimPopup(): boolean {
  return useModalOpen(ApplicationModal.CLAIM_POPUP)
}

export function useToggleShowClaimPopup(): () => void {
  return useToggleModal(ApplicationModal.CLAIM_POPUP)
}

export function useToggleSelfClaimModal(): () => void {
  return useToggleModal(ApplicationModal.SELF_CLAIM)
}

export function useToggleDelegateModal(): () => void {
  return useToggleModal(ApplicationModal.DELEGATE)
}

export function useToggleVoteModal(): () => void {
  return useToggleModal(ApplicationModal.VOTE)
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }))
    },
    [dispatch]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter(item => item.show), [list])
}
