import { Currency, CurrencyAmount, ETHER, JSBI, Pair, Percent, Price, TokenAmount } from '@uniswap/sdk'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PairState, usePair, usePairAddress } from '../../data/Reserves'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { wrappedCurrency, wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { AppDispatch, AppState } from '../index'
import { tryParseAmount } from '../swap/hooks'
import { useCurrencyBalances } from '../wallet/hooks'
import { Field, typeInput } from './actions'
import { getRouterContractShort, getPairContractConfirmed } from '../../utils'
// import {  Web3Provider } from '@ethersproject/providers'
import { useSingleCallResult, useSingleContractMultipleData } from '../../state/multicall/hooks'
import { useExecuteVirtualOrdersInterface, useExecuteVirtualOrdersInterfaceV2 } from '../../data/Reserves'
import { useBlockNumber } from '../../state/application/hooks'
import { BigNumber, utils } from 'ethers'

const ZERO = JSBI.BigInt(0)

export function useMintState(): AppState['mint'] {
  return useSelector<AppState, AppState['mint']>(state => state.mint)
}

export function useMintActionHandlers(
  noLiquidity: boolean | undefined
): {
  onFieldAInput: (typedValue: string) => void
  onFieldBInput: (typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onFieldAInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_A, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )
  const onFieldBInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_B, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )

  return {
    onFieldAInput,
    onFieldBInput
  }
}

export function useDerivedMintInfo(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined
): {
  dependentField: Field
  currencies: { [field in Field]?: Currency }
  pair?: Pair | null
  pairState: PairState
  pairRawAddress?: string
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  price?: Price
  noLiquidity?: boolean
  liquidityMinted?: TokenAmount
  poolTokenPercentage?: Percent
  error?: string
} {
  const { account, chainId, library } = useActiveWeb3React()

  const { independentField, typedValue, otherTypedValue } = useMintState()

  const dependentField = independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A

  // tokens
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA ?? undefined,
      [Field.CURRENCY_B]: currencyB ?? undefined
    }),
    [currencyA, currencyB]
  )

  let currentBlockNumber = useBlockNumber()

  // pair
  const [pairState, pair] = usePair(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B])
  // const { result: pairRawAddressRaw } = useSingleCallResult(
  //   library && account ? getRouterContractShort(library, account) : undefined,
  //  'obtainPairAddressRaw',
  //  [pair?.token0.address, pair?.token1.address]
  //  )

  // let [
  //   reserveA,
  //   reserveB,
  //   lastVirtualOrderBlock,
  //   currentSalesRateA,
  //   currentSalesRateB,
  //   rewardFactorA,
  //   rewardFactorB
  // ] = useExecuteVirtualOrdersInterface([currentBlockNumber ?? 0], pair, !!pair)[0]

  const expiriesList = useSingleContractMultipleData(
    pair && library && account ? getPairContractConfirmed(pair.liquidityToken.address, library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    !!pair
  )
  const preprocessedExpiryList = expiriesList[0]?.result as any
  let reserveA: BigNumber
  let reserveB: BigNumber
  let lastVirtualOrderBlock: number
  let currentSalesRateA: BigNumber
  let currentSalesRateB: BigNumber
  let rewardFactorA: BigNumber
  let rewardFactorB: BigNumber
  ///////////////////////// below for old changes /////////////////////////////
  const stateInfoSinceLastExecutionMap = useExecuteVirtualOrdersInterfaceV2(
    !!preprocessedExpiryList ? preprocessedExpiryList[0].map((e: BigNumber) => e?.toNumber()) : [0],
    pair,
    !!pair
  )
  if (!!currentBlockNumber && !!stateInfoSinceLastExecutionMap && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    ;[
      reserveA,
      reserveB,
      lastVirtualOrderBlock,
      currentSalesRateA,
      currentSalesRateB,
      rewardFactorA,
      rewardFactorB
    ] = stateInfoSinceLastExecutionMap[currentBlockNumber.toString()]
  } else {
    ;[reserveA, reserveB, lastVirtualOrderBlock, currentSalesRateA, currentSalesRateB, rewardFactorA, rewardFactorB] = [
      BigNumber.from(0),
      BigNumber.from(0),
      0,
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0)
    ]
  }

  const pairRawAddress = usePairAddress(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B])[0]

  const totalSupply = useTotalSupply(pair?.liquidityToken)

  const noLiquidity: boolean =
    pairState === PairState.NOT_EXISTS || Boolean(totalSupply && JSBI.equal(totalSupply.raw, ZERO))

  // balances
  const balances = useCurrencyBalances(account ?? undefined, [
    currencies[Field.CURRENCY_A],
    currencies[Field.CURRENCY_B]
  ])
  const currencyBalances: { [field in Field]?: CurrencyAmount } = {
    [Field.CURRENCY_A]: balances[0],
    [Field.CURRENCY_B]: balances[1]
  }

  // amounts
  const independentAmount: CurrencyAmount | undefined = tryParseAmount(typedValue, currencies[independentField])
  const dependentAmount: CurrencyAmount | undefined = useMemo(() => {
    if (noLiquidity) {
      if (otherTypedValue && currencies[dependentField]) {
        return tryParseAmount(otherTypedValue, currencies[dependentField])
      }
      return undefined
    } else if (independentAmount) {
      // we wrap the currencies just to get the price in terms of the other token
      const wrappedIndependentAmount = wrappedCurrencyAmount(independentAmount, chainId)
      const [tokenA, tokenB] = [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
      if (
        tokenA &&
        tokenB &&
        wrappedIndependentAmount &&
        pair &&
        reserveA &&
        reserveB &&
        reserveA.gt(BigNumber.from(0)) &&
        reserveB.gt(BigNumber.from(0))
      ) {
        const dependentCurrency = dependentField === Field.CURRENCY_B ? currencyB : currencyA
        //@ts-ignore
        const newPair = pair.setTokenAmounts(reserveA, reserveB)
        const dependentTokenAmount =
          dependentField === Field.CURRENCY_B
            ? newPair.priceOf(tokenA).quote(wrappedIndependentAmount)
            : newPair.priceOf(tokenB).quote(wrappedIndependentAmount)
        return dependentCurrency === ETHER ? CurrencyAmount.ether(dependentTokenAmount.raw) : dependentTokenAmount
      }
      return undefined
    } else {
      return undefined
    }
  }, [
    noLiquidity,
    independentAmount,
    otherTypedValue,
    currencies,
    dependentField,
    chainId,
    currencyA,
    currencyB,
    pair,
    reserveA,
    reserveB
  ])
  const parsedAmounts: { [field in Field]: CurrencyAmount | undefined } = useMemo(
    () => ({
      [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentAmount : dependentAmount,
      [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentAmount : independentAmount
    }),
    [dependentAmount, independentAmount, independentField]
  )

  const price = useMemo(() => {
    if (noLiquidity) {
      const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
      if (currencyAAmount && currencyBAmount) {
        return new Price(currencyAAmount.currency, currencyBAmount.currency, currencyAAmount.raw, currencyBAmount.raw)
      }
      return undefined
    } else {
      const wrappedCurrencyA = wrappedCurrency(currencyA, chainId)
      return pair && wrappedCurrencyA ? pair.priceOf(wrappedCurrencyA) : undefined
    }
  }, [chainId, currencyA, noLiquidity, pair, parsedAmounts])

  // liquidity minted
  const liquidityMinted = useMemo(() => {
    const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
    const [tokenAmountA, tokenAmountB] = [
      wrappedCurrencyAmount(currencyAAmount, chainId),
      wrappedCurrencyAmount(currencyBAmount, chainId)
    ]
    if (
      pair &&
      totalSupply &&
      tokenAmountA &&
      tokenAmountB &&
      reserveA &&
      reserveB &&
      reserveA.gt(BigNumber.from(0)) &&
      reserveB.gt(BigNumber.from(0))
    ) {
      let getLiquidityMinted
      // @ts-ignore
      const tokenAmounts = tokenAmountA.currency.sortsBefore(tokenAmountB.currency) // does safety checks
        ? [tokenAmountA, tokenAmountB]
        : [tokenAmountB, tokenAmountA]
      // @ts-ignore
      const reserves = tokenAmountA.currency.sortsBefore(tokenAmountB.currency) // does safety checks
        ? [reserveA, reserveB]
        : [reserveB, reserveA]
      // @ts-ignore
      let new_pair = pair.setTokenAmounts(reserves[0], reserves[1])
      // // @ts-ignore
      // if (tokenAmounts[1]?.currency?.equals(pair?.token0)) {
      //   new_pair = new Pair(pair.reserve1, pair.reserve0)
      //   // @ts-ignore
      //   new_pair.liquidityToken = pair.liquidityToken
      // } else {
      //   new_pair = pair
      // }

      new_pair
      // getLiquidityMinted = wrappedCurrencyAmount(new_pair.getLiquidityMinted(totalSupply, tokenAmounts[0], tokenAmounts[1]), chainId)
      getLiquidityMinted = new_pair.getLiquidityMinted(totalSupply, tokenAmounts[0], tokenAmounts[1])
      return getLiquidityMinted
    } else {
      return undefined
    }
  }, [parsedAmounts, chainId, pair, totalSupply, reserveA, reserveB])

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted && totalSupply && !!liquidityMinted) {
      return new Percent(liquidityMinted.raw, totalSupply.add(liquidityMinted).raw)
    } else {
      return undefined
    }
  }, [liquidityMinted, totalSupply])

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  if (pairState === PairState.INVALID) {
    error = error ?? 'Invalid pair'
  }

  if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    error = error ?? 'Enter an amount'
  }

  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
  //const { chainId } = useActiveWeb3React()
  const checkMantleBase = chainId === 5000 || chainId === 5001

  const CURRENCY_A_SYMBOL = checkMantleBase
    ? currencies[Field.CURRENCY_A]?.symbol === 'ETH'
      ? 'MNT'
      : currencies[Field.CURRENCY_A]?.symbol
    : currencies[Field.CURRENCY_A]?.symbol

  const CURRENCY_B_SYMBOL = checkMantleBase
    ? currencies[Field.CURRENCY_B]?.symbol === 'ETH'
      ? 'MNT'
      : currencies[Field.CURRENCY_B]?.symbol
    : currencies[Field.CURRENCY_B]?.symbol

  if (currencyAAmount && currencyBalances?.[Field.CURRENCY_A]?.lessThan(currencyAAmount)) {
    error = 'Insufficient ' + CURRENCY_A_SYMBOL + ' balance'
  }

  if (currencyBAmount && currencyBalances?.[Field.CURRENCY_B]?.lessThan(currencyBAmount)) {
    error = 'Insufficient ' + CURRENCY_B_SYMBOL + ' balance'
  }

  const tokenRename = (tName: any) => {
    if (checkMantleBase) {
      if (tName === 'ETH') return 'MNT'
      // if (tName === 'WETH') return 'WMNT'
    }
    return tName
  }

  if (currencies.CURRENCY_A?.symbol === 'ETH' || currencies.CURRENCY_A?.symbol === 'WETH') {
    //@ts-ignore
    currencies.CURRENCY_A.symbol = tokenRename(currencies.CURRENCY_A.symbol)
  }

  if (currencies.CURRENCY_B?.symbol === 'ETH' || currencies.CURRENCY_B?.symbol === 'WETH') {
    //@ts-ignore
    currencies.CURRENCY_B.symbol = tokenRename(currencies.CURRENCY_B.symbol)
  }

  return {
    dependentField,
    currencies,
    pair,
    pairState,
    pairRawAddress,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  }
}
