import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
import useENS from '../../hooks/useENS'
import { Version } from '../../hooks/useToggledVersion'
import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount, Trade, Price, Fraction } from '@uniswap/sdk'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useV1Trade } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useTradeExactIn, useTradeExactOut } from '../../hooks/Trades'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { isAddress, getTWAMMContract } from '../../utils'
import { AppDispatch, AppState } from '../index'
import { useCurrencyBalances } from '../wallet/hooks'
import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { SwapState } from './reducer'
import useToggledVersion from '../../hooks/useToggledVersion'
import { useUserSlippageTolerance } from '../user/hooks'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'
import { useBlockNumber } from '../../state/application/hooks'
import {
  usePair,
  PairState,
  useExecuteVirtualOrdersInterface,
  useExecuteVirtualOrdersInterfaceV2
} from '../../data/Reserves'
import { useSingleCallResult, useSingleContractMultipleData, Result } from '../../state/multicall/hooks'
import { getRouterContractShort, getPairContractConfirmed } from '../../utils'

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>(state => state.swap)
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token
              ? currency.address
              : currency === ETHER
              ? chainId !== 5000 && chainId !== 5001
                ? 'ETH'
                : 'MNT'
              : ''
        })
      )
    },
    [chainId, dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient
  }
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()

    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D' // v2 router 02
]

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade: Trade, checksummedAddress: string): boolean {
  return (
    trade.route.path.some(token => token.address === checksummedAddress) ||
    trade.route.pairs.some(pair => pair.liquidityToken.address === checksummedAddress)
  )
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmount: CurrencyAmount | undefined
  v2Trade: Trade | undefined
  inputError?: string
  v1Trade: Trade | undefined
  resTrade?: any
  marketPrice?: any
} {
  const { account, chainId, library } = useActiveWeb3React()

  const toggledVersion = useToggledVersion()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
    blockValue
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const recipientLookup = useENS(recipient ?? undefined)

  const [pairState, Pair] = usePair(inputCurrency ?? undefined, outputCurrency ?? undefined)

  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null

  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined
  ])

  const isExactIn: boolean = independentField === Field.INPUT
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)

  // const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined)
  // const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn ? parsedAmount : undefined)

  // const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1]
    }),
    [relevantTokenBalances]
  )

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined
    }),
    [inputCurrency, outputCurrency]
  )

  // get link to trade on v1, if a better rate exists
  let inputError: string | undefined
  if (!account) {
    inputError = 'Connect Wallet'
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient'
  } else {
    if (BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1) {
      inputError = inputError ?? 'Invalid recipient'
    }
  }

  const [allowedSlippage] = useUserSlippageTolerance()

  // const slippageAdjustedAmounts = v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage)

  // const slippageAdjustedAmountsV1 =
  //   v1Trade && allowedSlippage && computeSlippageAdjustedAmounts(v1Trade, allowedSlippage)

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [currencyBalances[Field.INPUT], parsedAmount]

  let resTrade = getTWAMMContract(chainId, library, account)

  if (resTrade) {
    // @ts-ignore
    resTrade.route = getTWAMMContract(chainId, library, account)
    // @ts-ignore
    resTrade.pair = Pair
  }
  let currentBlockNumber = useBlockNumber()
  let tokenAddrSplit = [
    [
      Pair ? Pair.token0.address : '0x0000000000000000000000000000000000000000',
      Pair ? Pair.token1.address : '0x0000000000000000000000000000000000000000'
    ]
  ]
  let pairExist =
    Pair &&
    Pair.token0.address !== '0x0000000000000000000000000000000000000000' &&
    Pair.token1.address !== '0x0000000000000000000000000000000000000000'
  const expiriesList = useSingleContractMultipleData(
    Pair && library && account ? getPairContractConfirmed(Pair.liquidityToken.address, library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    !!Pair
  )
  const preprocessedExpiryList = expiriesList[0]?.result as any
  let reserveATmp
  let reserveBTmp
  let lastVirtualOrderBlock: number
  let currentSalesRateA
  let currentSalesRateB
  let rewardFactorA
  let rewardFactorB
  ///////////////////////// below for old changes /////////////////////////////
  const stateInfoSinceLastExecutionMap = useExecuteVirtualOrdersInterfaceV2(
    !!preprocessedExpiryList ? preprocessedExpiryList[0].map((e: BigNumber) => e?.toNumber()) : [0],
    Pair,
    pairState === PairState.EXISTS
  )

  if (!!currentBlockNumber && currentBlockNumber != 0 && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    ;[
      reserveATmp,
      reserveBTmp,
      lastVirtualOrderBlock,
      currentSalesRateA,
      currentSalesRateB,
      rewardFactorA,
      rewardFactorB
    ] = stateInfoSinceLastExecutionMap[currentBlockNumber.toString()]
  } else {
    ;[
      reserveATmp,
      reserveBTmp,
      lastVirtualOrderBlock,
      currentSalesRateA,
      currentSalesRateB,
      rewardFactorA,
      rewardFactorB
    ] = [
      BigNumber.from(0),
      BigNumber.from(0),
      0,
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0)
    ]
  }

  let reserveFrom
  let reserveTo

  let rawFrom
  let rawTo
  let marketPrice: any

  if (Pair && currentBlockNumber && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    if (Pair.token0.address <= Pair.token1.address) {
      ;[reserveFrom, reserveTo] = [
        new TokenAmount(Pair.token0, JSBI.BigInt(reserveATmp.toString())),
        new TokenAmount(Pair.token1, JSBI.BigInt(reserveBTmp.toString()))
      ]
      rawFrom = JSBI.BigInt(reserveATmp.toString())
      rawTo = JSBI.BigInt(reserveBTmp.toString())
    } else {
      ;[reserveFrom, reserveTo] = [
        new TokenAmount(Pair.token0, JSBI.BigInt(reserveBTmp.toString())),
        new TokenAmount(Pair.token1, JSBI.BigInt(reserveATmp.toString()))
      ]
      rawFrom = JSBI.BigInt(reserveBTmp.toString())
      rawTo = JSBI.BigInt(reserveATmp.toString())
    }
  } else {
    ;[reserveFrom, reserveTo] = [undefined, undefined]
  }

  if (reserveFrom && reserveTo) {
    marketPrice = reserveTo.divide(reserveFrom)

    // @ts-ignore
    resTrade.marketPrice = marketPrice =
      inputCurrency && new Price(inputCurrency, reserveTo.token, marketPrice.denominator, marketPrice.numerator)

    if (inputCurrency && resTrade?.marketPrice && !!marketPrice) {
      inputCurrency.decimals > reserveTo.token.decimals
      let res = new Fraction(
        JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0)),
        JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0))
      )

      resTrade.marketPrice.scalar = res
      //@ts-ignore
      marketPrice.scalar = res
    }
  }

  const compareVal = inputCurrency && isExactIn ? reserveFrom : reserveTo
  //const { chainId } = useActiveWeb3React()
  const checkMantleBase = chainId === 5000 || chainId === 5001

  if (!isExactIn && reserveFrom && reserveTo && amountIn && compareVal && compareVal.lessThan(amountIn)) {
    inputError = checkMantleBase
      ? amountIn.currency.symbol === 'ETH'
        ? 'Out of MNT reserve'
        : 'Out of ' + amountIn.currency.symbol + ' reserve'
      : 'Out of ' + amountIn.currency.symbol + ' reserve'
  }

  if (isExactIn && balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = checkMantleBase
      ? amountIn.currency.symbol === 'ETH'
        ? 'Insufficient MNT balance'
        : 'Insufficient ' + amountIn.currency.symbol + ' balance'
      : 'Insufficient ' + amountIn.currency.symbol + ' balance'
  }

  if (
    !(reserveFrom?.greaterThan(JSBI.BigInt(0)) && reserveTo?.greaterThan(JSBI.BigInt(0))) &&
    amountIn &&
    !inputError
  ) {
    inputError = 'Insufficient liquidity for this trade.'
  }

  if (
    !inputError &&
    !!resTrade &&
    Field.INPUT &&
    Field.OUTPUT &&
    pairState === PairState.EXISTS &&
    reserveFrom &&
    reserveTo &&
    rawFrom &&
    rawTo &&
    parsedAmount
  ) {
    let numval: any = new Number(typedValue)

    let amountIn = parsedAmount?.raw

    let amountOut =
      inputCurrency && isExactIn
        ? reserveTo
            ?.multiply(amountIn)
            .divide(reserveFrom?.add(new TokenAmount(reserveFrom.token, amountIn)))
            .multiply(JSBI.BigInt(997))
            .divide(JSBI.BigInt(1000))
        : reserveFrom
            ?.multiply(amountIn)
            .divide(reserveTo?.subtract(new TokenAmount(reserveTo.token, amountIn)))
            .multiply(JSBI.BigInt(1000))
            .divide(JSBI.BigInt(997))

    if (inputCurrency && inputCurrency?.decimals !== reserveTo.token.decimals) {
      let res
      if (inputCurrency?.decimals - reserveTo.token.decimals <= 0) {
        res = new Fraction(
          JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(Math.abs(inputCurrency?.decimals - reserveTo.token.decimals))),
          JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0))
        )
      } else {
        res = new Fraction(
          JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0)),
          JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(Math.abs(inputCurrency?.decimals - reserveTo.token.decimals)))
        )
      }

      if (isExactIn) {
        amountOut = amountOut.multiply(res)
      }
    }

    Pair?.reserve0.multiply
    // @ts-ignore
    resTrade.inputAmount = tryParseAmount(
      isExactIn
        ? typedValue
        : amountOut
        ? (Number(amountOut.quotient.toString()) / 10 ** reserveTo.token.decimals).toString()
        : undefined,
      inputCurrency ?? undefined
    )
    // @ts-ignore
    resTrade.outputAmount = tryParseAmount(
      !isExactIn
        ? typedValue
        : amountOut
        ? (Number(amountOut.quotient.toString()) / 10 ** reserveTo.token.decimals).toString()
        : undefined,
      outputCurrency ?? undefined
    )

    // let averagePrice = reserveTo.divide(reserveFrom)
    // // @ts-ignore
    // resTrade.averagePrice =
    //   inputCurrency && new Price(inputCurrency, reserveTo.token, averagePrice.denominator, averagePrice.numerator)

    // if (inputCurrency && resTrade?.averagePrice) {
    //   inputCurrency.decimals > reserveTo.token.decimals
    //   let res = new Fraction(
    //     JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0)),
    //     JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0))
    //   )

    //   resTrade.averagePrice.scalar = res
    // }

    let executionPrice = reserveTo
      .divide(reserveFrom.add(new TokenAmount(reserveFrom.token, isExactIn ? amountIn : amountOut.quotient)))
      .multiply(JSBI.BigInt(997))
      .divide(JSBI.BigInt(1000))

    // @ts-ignore
    resTrade.executionPrice =
      inputCurrency && new Price(inputCurrency, reserveTo.token, executionPrice.denominator, executionPrice.numerator)

    if (inputCurrency && resTrade?.executionPrice) {
      inputCurrency.decimals > reserveTo.token.decimals
      let res = new Fraction(
        JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0)),
        JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0))
      )

      resTrade.executionPrice.scalar = res
    }

    let Fee = JSBI.divide(JSBI.multiply(isExactIn ? amountOut.quotient : amountIn, JSBI.BigInt(3)), JSBI.BigInt(997))

    let target = new TokenAmount(
      reserveTo.token,
      JSBI.greaterThan(isExactIn ? amountOut.quotient : amountIn, reserveTo.quotient)
        ? JSBI.divide(
            isExactIn ? amountOut.quotient : amountIn,
            JSBI.BigInt(parseInt(1 * 10 ** reserveTo.token.decimals + '', 10))
          )
        : isExactIn
        ? amountOut.quotient
        : amountIn
    )

    const testa = new TokenAmount(reserveTo.token, JSBI.BigInt(100))
    const testb = new TokenAmount(reserveFrom.token, JSBI.BigInt(100))

    let PriceImpact =
      (reserveTo.greaterThan(JSBI.BigInt(0)) || Number(reserveTo.quotient.toString()) > 0) &&
      reserveFrom.greaterThan(JSBI.BigInt(0))
        ? reserveFrom
            .divide(reserveTo)
            .multiply(reserveTo.subtract(new TokenAmount(reserveTo.token, isExactIn ? amountOut.quotient : amountIn)))
            .divide(reserveFrom.add(new TokenAmount(reserveFrom.token, isExactIn ? amountIn : amountOut.quotient)))
            .toSignificant(4)
        : 0 + ''
    // @ts-ignore
    resTrade.PriceImpactBlock = undefined

    // @ts-ignore
    resTrade.Fees = tryParseAmount(
      (Number(Fee.toString()) / 10 ** reserveTo.token.decimals).toString(),
      outputCurrency ?? undefined
    )

    // @ts-ignore
    resTrade.PriceImpact = (100 - PriceImpact * 100).toFixed(2)

    if (!Number.isNaN(Number(blockValue))) {
      let blockAmountIn = JSBI.divide(
        JSBI.BigInt(amountIn.toString()),
        JSBI.BigInt(5 - (Number(currentBlockNumber) % 5) + Number(blockValue) * 5)
      )

      let amountInToken = new TokenAmount(
        reserveFrom.token,
        JSBI.divide(
          JSBI.BigInt(amountIn.toString()),
          JSBI.BigInt(5 - (Number(currentBlockNumber) % 5) + Number(blockValue) * 5)
        )
      )

      let scaledNum = JSBI.divide(
        JSBI.BigInt(amountIn.toString()),
        JSBI.BigInt(5 - (Number(currentBlockNumber) % 5) + Number(blockValue) * 5)
      )

      let decimalDiff = Math.abs(reserveTo.token.decimals - reserveFrom.token.decimals)

      if (reserveTo.token.decimals > reserveFrom.token.decimals) {
        scaledNum = JSBI.multiply(scaledNum, JSBI.BigInt(10 ** decimalDiff))
      }

      if (reserveTo.token.decimals < reserveFrom.token.decimals) {
        scaledNum = JSBI.divide(scaledNum, JSBI.BigInt(10 ** decimalDiff))
      }

      let amountOutToken = new TokenAmount(reserveTo.token, scaledNum)

      console.log(
        'blockAmountIn',
        blockAmountIn.toString(),
        JSBI.BigInt(5 - (Number(currentBlockNumber) % 5) + Number(blockValue) * 5).toString()
      )

      let rawReserveTo = reserveTo.raw
      let rawReserveFrom = reserveFrom.raw

      let testblockAmountOut = reserveTo
        ?.multiply(amountOutToken)
        .divide(reserveFrom?.add(amountInToken))
        .multiply(JSBI.BigInt(997))
        .divide(JSBI.BigInt(1000))
        .toFixed(4)

      let target = new TokenAmount(reserveFrom.token, amountInToken.raw).add(reserveFrom).raw

      let blockAmountOut = new TokenAmount(
        reserveTo.token,
        JSBI.divide(
          JSBI.multiply(
            JSBI.divide(
              JSBI.multiply(rawTo, amountOutToken.raw),
              reserveFrom.token.decimals < reserveTo.token.decimals
                ? JSBI.multiply(target, JSBI.BigInt(10 ** reserveTo.token.decimals))
                : target
            ),
            JSBI.BigInt(997)
          ),
          JSBI.BigInt(1000)
        )
      )

      if (inputCurrency && inputCurrency?.decimals !== reserveTo.token.decimals) {
        let res = new Fraction(
          JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(Math.abs(inputCurrency?.decimals - reserveTo.token.decimals))),
          JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0))
        )
      }

      let test11 = new TokenAmount(
        reserveTo.token,
        tryParseAmount(testblockAmountOut, outputCurrency || undefined)?.raw || JSBI.BigInt(0)
      )

      let PriceImpactBlock =
        reserveTo.greaterThan(JSBI.BigInt(0)) &&
        reserveFrom.greaterThan(JSBI.BigInt(0)) &&
        reserveTo.greaterThan(blockAmountOut)
          ? reserveFrom
              .divide(reserveTo)
              .multiply(reserveTo.subtract(blockAmountOut))
              .divide(reserveFrom.add(amountInToken))
              .toSignificant(4)
          : 0

      let newRes =
        test11 &&
        reserveFrom
          .divide(reserveTo)
          .multiply(reserveTo.subtract(new TokenAmount(reserveTo.token, test11.raw)))
          .divide(reserveFrom.add(amountInToken))
          .toSignificant(4)

      // @ts-ignore
      resTrade.PriceImpactBlock = (
        100 -
        Number(reserveTo.token.decimals === reserveFrom.token.decimals ? PriceImpactBlock : newRes) * 100
      ).toFixed(2)
    }
  } else {
    resTrade = undefined
  }

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      v2Trade: undefined,
      v1Trade: undefined,
      // v2Trade: v2Trade ?? undefined,
      inputError,
      // v1Trade,
      resTrade,
      marketPrice
    }),
    [currencies, currencyBalances, inputError, parsedAmount, resTrade, marketPrice]
  )
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    if (urlParam.toUpperCase() === 'ETH') return 'ETH'
    if (valid === false) return 'ETH'
  }
  return 'ETH' ?? ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient
  }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  useEffect(() => {
    if (!chainId) return
    const parsed = queryParametersToSwapState(parsedQs)

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId,
        recipient: parsed.recipient
      })
    )

    setResult({ inputCurrencyId: parsed[Field.INPUT].currencyId, outputCurrencyId: parsed[Field.OUTPUT].currencyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return result
}
