import { Currency, ETHER, Token } from '@uniswap/sdk'
import { useActiveWeb3React } from '../hooks'

export function currencyId(currency: Currency, chainId: any = null): string {
  if ((currency === ETHER && chainId == 5000) || (currency === ETHER && chainId == 5001)) return 'MNT'
  if (currency === ETHER) return 'ETH'
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export function useCurrencyId(): Function {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { chainId } = useActiveWeb3React()
  // if (currency === ETHER) return 'ETH'
  // const checkMantleBase = (currency === ETHER && chainId === 5000) || chainId === 5001

  // if (checkMantleBase) {
  //   return 'MNT'
  // } else {
  //   return 'ETH'
  // }

  return (currency: any) => currencyId(currency, chainId)
}
