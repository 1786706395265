import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Modal from '.'
import { Text } from 'rebass'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween, RowFixed, AutoRow } from '../Row'
import { ButtonPrimary, ButtonLight, ButtonPrimary2 } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'

import { InputNumber, Space } from 'antd'

const Wrapper = styled.div`
  width: 100%;
`
const HLayoutWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  width: 100%;
`

const ItemWrapper = styled(HLayoutWrapper)`
  flex: 1;
`

const Section = styled(AutoColumn)`
  padding: 15px 20px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const LabelText = styled.div<{ label?: boolean }>`
  // width: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: ${({ label }) => (label ? '10px' : '0')};
`

let periodHour = 0
let periodMin = 0
let periodDay = 0
let periodMon = 0
let blocksNumber = 0

function InputItem({
  type,
  sectionTitle,
  min,
  max,
  label,
  onChanged
}: {
  type?: string
  sectionTitle?: string
  min?: number
  max?: number
  label?: string
  onChanged: (val: any) => void
}) {
  return (
    <AutoColumn style={{ flex: 1 }}>
      {sectionTitle ? <LabelText style={{ marginBottom: '9px' }}>{sectionTitle}</LabelText> : <></>}
      <ItemWrapper>
        <InputNumber
          size={'large'}
          style={{ backgroundColor: 'rgba(2, 97, 245, 0.1)', width: '100%', borderRadius: '10px' }}
          min={min}
          max={max}
          defaultValue={0}
          onChange={onChanged}
          bordered={false}
        />
        {label ? <LabelText label={true}>{label}</LabelText> : <></>}
      </ItemWrapper>
    </AutoColumn>
  )
}

function ConfirmationContent({ onConfirm, onDismiss }: { onConfirm: () => void; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
        <AutoColumn gap="20px" justify={'center'}>
          <HLayoutWrapper>
            <InputItem
              sectionTitle="Custom period"
              label="minutes"
              min={2}
              onChanged={val => {
                periodMin = val
                console.log(periodMin)
              }}
            ></InputItem>
          </HLayoutWrapper>

          <HLayoutWrapper>
            <InputItem
              label="hours"
              min={0}
              onChanged={val => {
                periodHour = val
                console.log(periodHour)
              }}
            ></InputItem>
          </HLayoutWrapper>

          <HLayoutWrapper>
            <InputItem
              label="days"
              min={0}
              onChanged={val => {
                periodDay = val
                console.log(periodDay)
              }}
            ></InputItem>
          </HLayoutWrapper>

          <HLayoutWrapper>
            <InputItem
              label="months"
              min={0}
              onChanged={val => {
                periodMon = val
                console.log(periodMon)
              }}
            ></InputItem>
          </HLayoutWrapper>

          <HLayoutWrapper>
            <InputItem
              sectionTitle="Integer blocks (unit: 5 blocks)"
              onChanged={val => {
                blocksNumber = val
                console.log(blocksNumber)
              }}
            ></InputItem>
          </HLayoutWrapper>

          <HLayoutWrapper>
            <ButtonPrimary2 onClick={onDismiss} style={{ flex: 1, height: '46px', backgroundColor: theme.lightBlue }}>
              <RowFixed>Cancel</RowFixed>
            </ButtonPrimary2>
            <ButtonLight onClick={onConfirm} style={{ flex: 1, height: '46px', marginLeft: '14px' }}>
              <RowFixed>Confirm</RowFixed>
            </ButtonLight>
          </HLayoutWrapper>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationHeader({ title }: { title: string }) {
  return (
    <Wrapper style={{ marginTop: '10px' }}>
      <RowBetween>
        <div></div>
        <Text fontWeight={500} fontSize={20}>
          {title}
        </Text>
        <div></div>
      </RowBetween>
    </Wrapper>
  )
}

function ConfirmationFooter({ onConfirm, onDismiss }: { onConfirm: () => void; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <HLayoutWrapper>
        <ButtonPrimary2
          onClick={onDismiss}
          style={{ flex: 1, height: '46px', marginLeft: '14px', backgroundColor: theme.lightBlue }}
        >
          <RowFixed>Cancel</RowFixed>
        </ButtonPrimary2>

        <ButtonLight onClick={onConfirm} style={{ flex: 1, height: '46px' }}>
          <RowFixed>Confirm</RowFixed>
        </ButtonLight>
      </HLayoutWrapper>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  title: string
  onDismiss: () => void
  onConfirm: ({ hour, minutes, blocks }: { hour: number; minutes: number; blocks: number }) => void
}

export default function PIInputConfirm({ isOpen, title, onDismiss, onConfirm }: ConfirmationModalProps) {
  // confirmation screen
  // let isShow = isOpen

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Wrapper>
        <AutoColumn gap="5px" justify="center">
          <ConfirmationHeader title={title}></ConfirmationHeader>
          <ConfirmationContent
            onDismiss={onDismiss}
            onConfirm={() => {
              onConfirm({
                hour: periodHour,
                minutes: periodMin,
                blocks: blocksNumber
              })
            }}
          />
          {/* <ConfirmationFooter onDismiss={onDismiss} onConfirm={onConfirm}></ConfirmationFooter> */}
        </AutoColumn>
      </Wrapper>
    </Modal>
  )
}
