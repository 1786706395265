import React, { ReactElement, useContext } from 'react'
import styled, { CSSProperties, ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { Text } from 'rebass'
import { CloseIcon } from '../../theme/components'
import { RowBetween, RowFixed, AutoRow } from '../Row'
import { AlertTriangle } from 'react-feather'
import { ButtonPrimary, ButtonLight, ButtonPrimary2 } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'

const Wrapper = styled.div`
  width: 100%;
`

const HLayoutWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 15px 20px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

function ConfirmContent({
  title,
  content,
  onConfirm,
  onDismiss,
  contentStyle
}: {
  title?: string
  content?: string | ReactElement
  onConfirm: () => void
  onDismiss: () => void
  contentStyle?: CSSProperties | undefined
}) {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
        <AutoColumn gap="20px" justify={'center'}>
          <RowBetween>
            <div></div>
            <Text fontWeight={500} fontSize={20}>
              {title}
            </Text>
            <div></div>
          </RowBetween>

          <div style={contentStyle}>
            <Text style={contentStyle} fontSize={16}>
              {content}
            </Text>
          </div>

          <HLayoutWrapper>
            <ButtonLight onClick={onConfirm} style={{ flex: 1, height: '46px' }}>
              <RowFixed>Confirm</RowFixed>
            </ButtonLight>

            <ButtonPrimary2
              onClick={onDismiss}
              style={{ flex: 1, height: '46px', marginLeft: '14px', backgroundColor: theme.lightBlue }}
            >
              <RowFixed>Cancel</RowFixed>
            </ButtonPrimary2>
          </HLayoutWrapper>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent()}
      </Section>
      <BottomSection gap="12px">{bottomContent()}</BottomSection>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            Error
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
          <Text fontWeight={500} fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>
      </BottomSection>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  title: string
  content: string | ReactElement
  onDismiss: () => void
  onConfirm: () => void
  contentStyle?: CSSProperties
}

export default function PIConfirm({
  isOpen,
  title,
  content,
  onDismiss,
  onConfirm,
  contentStyle
}: ConfirmationModalProps) {
  // confirmation screen
  // let isShow = isOpen

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <ConfirmContent
        title={title}
        content={content}
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        contentStyle={contentStyle}
      />
    </Modal>
  )
}
