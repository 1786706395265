import React from 'react'
import styled from 'styled-components'

import SwapLongTerm from '.'
import OrderList from './OrderList'
import CompletedOrderList from './CompletedOrderList'
import KLine from './KLine'
import { StateInfo } from './StateInfo'

export const BodyWrapper = styled.div`
  display: flex;
  flex-flow: column;

  max-width: 1150px;
  width: 100%;
`

export const HLayoutWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`

export const KLineWrapper = styled.div`
  margin-right: 20px;
  padding-top: 8px;

  max-width: 720px;
  min-width: 620px;
  height: 520px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 16px;
`

export const StateWrapper = styled.div`
  margin-right: 20px;
  padding-top: 8px;

  max-width: 520px;
  min-width: 420px;
  height: 530px;
  background: ${({ theme }) => theme.bg1};
  border-radius: 16px;
`

export const LongTermWrapper = styled.div`
  display: flex;
  flex-flow: column;
  background: ${({ theme }) => theme.bg1};

  max-width: 720px;
  min-width: 620px;
  height: 530px;
  border-radius: 16px;
  z-index: 2;
`

export const OrderWrapper = styled.div`
  max-width: 1060px;
  min-width: 950px;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border-radius: 16px;
  margin-top: 20px;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function SwapLongTermPageBody() {
  const initialData = [
    { time: '2020-08-22', value: 3000.51 },
    { time: '2020-10-23', value: 3100.11 },
    { time: '2020-12-24', value: 2790.02 },
    { time: '2021-06-25', value: 2654.32 },
    { time: '2021-09-26', value: 2500.17 },
    { time: '2021-08-27', value: 2400.89 },
    { time: '2021-10-28', value: 2553.46 },
    { time: '2021-11-29', value: 2300.92 },
    { time: '2021-12-30', value: 2100.68 },
    { time: '2021-12-31', value: 1800.67 }
  ]

  return (
    <BodyWrapper>
      <HLayoutWrapper>
        {/* <KLineWrapper>
          <KLine colors={{}} data={initialData}></KLine>
        </KLineWrapper> */}

        <StateWrapper>
          <StateInfo></StateInfo>
        </StateWrapper>
        <LongTermWrapper>
          <SwapLongTerm></SwapLongTerm>
        </LongTermWrapper>
      </HLayoutWrapper>

      <OrderList></OrderList>

      {/* <HLayoutWrapper>
        <OrderWrapper>
          <OrderList></OrderList>
        </OrderWrapper>
      </HLayoutWrapper>

      <HLayoutWrapper>
        <OrderWrapper>
          <CompletedOrderList></CompletedOrderList>
        </OrderWrapper>
      </HLayoutWrapper> */}
    </BodyWrapper>
  )
}
