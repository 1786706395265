import React, { ReactElement, useState, useMemo } from 'react'
import styled from 'styled-components'
import { AutoRow } from '../../components/Row'
import { Currency, currencyEquals, ETHER, Percent, Token, WETH, ChainId } from '@uniswap/sdk'
import { ExternalLink as LinkIcon, X } from 'react-feather'
import { ExternalLink } from '../../theme'
import PIConfirm from '../../components/Modal/PIConfirm'
import { backgrounds, darken } from 'polished'
import SwapLongTermHeader from '../../components/swap/SwapLongTermHeader'
import { useDerivedSwapInfo } from '../../state/swap/hooks'
import { Field } from '../../state/swap/actions'
import {
  useTokensOrderIdDetails,
  useOrderWithdrawableProceedsByDetails,
  useOrderIdStatusCheck
} from '../../data/orderIdDetails'
import { useOrderIds } from '../../data/orderIds'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import {
  PairState,
  usePair,
  usePairAddress,
  useExecuteVirtualOrdersInterface,
  isZero,
  usePairBalance
} from '../../data/Reserves'
import { useBlockNumber } from 'state/application/hooks'
import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
import { utils } from 'ethers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useActiveWeb3React } from '../../hooks'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract, getTWAMMContract } from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { Select, Divider, Space } from 'antd'
import { getEtherscanLink } from '../../utils'
import { SwapOutlined } from '@ant-design/icons'

const { Option } = Select

const ListWrapper = styled.div`
  padding: 8px 0 15px 0;
  width: 100%;
`

const commonStyle = styled.div`
  min-width: 80px;
`
const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    padding-left: 0.2rem !important;
  }
`

const NumberWrapper = styled.span`
  font-size: 0.8rem;
  margin-right: 5px;
  display: inline-block;
`

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  text-align: center;

  padding: 0px 1.5rem 0px 1.5rem;
  width: 100%;
  /* min-width: 100px; */
  color: ${({ theme }) => theme.text5};

  position: sticky;
  top: -16px;
  background: #ffff;
`

const TableTitle = styled(commonStyle)<{ width?: number; last?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  /* flex: ${({ width }) => (width ? 1 : '100px')}; */
  flex: ${({ width }) => width || '100px'};

  border-right: ${({ last }) => (last ? '' : '2px dashed #c4c4c4')};

  height: 60px;
  color: ${({ theme }) => theme.tableTitleTextColor1};
  font-size: 14px;
`

const widthArr = [0.1, 0.1, 0.1, 2, 2, 2, 2.5, 2, 1.5, 2.5]

const TableHeader = function({
  title,
  width,
  last
}: {
  title: string | number | null | ReactElement
  width: number
  last: boolean | undefined
}) {
  return (
    <TableTitle width={width} last={last}>
      {title}
    </TableTitle>
  )
}

const ListItemWrapper = styled.div<{ index: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0px 1.5rem 0px 1.5rem;
  width: 100%;
  color: ${({ theme }) => theme.text5};
  background-color: ${({ theme, index }) => (index % 2 === 0 ? theme.customLightBlue1 : '')};
  text-align: center;
`

// const ListItem = styled(commonStyle)<{ width?: number }>`
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   /* flex: ${({ width }) => (width ? 1 : '100px')}; */
//   flex: ${({ width }) => width || '100px'};

//   height: 60px;
//   /* min-width: ${({ width }) => {
//     return '100px'
//   }}; */
//   color: ${({ theme }) => theme.text1};
//   font-size: 12px;
//   flex-wrap: wrap;
// `

const ListItem = styled(commonStyle)<{ width?: number; last?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  /* flex: ${({ width }) => (width ? 1 : '100px')}; */
  flex: ${({ width }) => width || '100px'};

  border-right: ${({ last }) => (last ? '' : '2px dashed #c4c4c4')};

  height: 60px;
  /* min-width: ${({ width }) => {
    return '100px'
  }}; */
  color: ${({ theme }) => theme.text1};
  font-size: 12px;
  flex-wrap: wrap;
`

interface ListItemProps {
  id?: string | number
  sell?: string | number
  buy?: string | number
  sold?: string | number
  sellRate?: string | number
  purchased?: string | number
  averagePrice?: string | number
  fee?: string | number
  duration?: string | number
  submit?: string | number
  token0?: Token | undefined
  token1?: Token | undefined
  number?: number | string | undefined
  active?: boolean | undefined
}

const TableContent = function({
  itemData,
  width,
  chainId,
  reverse
}: {
  itemData: ListItemProps
  width: number | undefined
  chainId: ChainId | undefined
  reverse: boolean | undefined
}) {
  const listItemData = useMemo(
    () => [
      {
        width,
        content: itemData.id
      },
      {
        width,
        content: itemData.sell
      },
      {
        width,
        content: itemData.buy
      },
      {
        width,
        content: ' ' + itemData.sell,
        number: itemData.sellRate
      },
      {
        width,
        content: ' ' + itemData.sell,
        number: itemData.sold
      },
      {
        width,
        content: ' ' + itemData.buy,
        number: itemData.purchased
      },
      {
        width,
        content: reverse ? ' ' + itemData.sell + ' per ' + itemData.buy : ' ' + itemData.buy + ' per ' + itemData.sell,
        number: reverse
          ? !!itemData?.averagePrice && itemData?.averagePrice > 0
            ? (1 / Number(itemData?.averagePrice)).toFixed(1 / Number(itemData?.averagePrice) > 1 ? 4 : 6)
            : 0
          : itemData.averagePrice ?? 0
      },
      {
        width,
        content: ' ' + itemData?.buy,
        number: itemData?.fee || '0'
      },
      {
        width,
        number: itemData.duration
      },
      {
        width,
        number: itemData.submit?.toString(),
        last: true
      }
    ],
    [
      width,
      itemData.id,
      itemData.sell,
      itemData.buy,
      itemData.sellRate,
      itemData.sold,
      itemData.purchased,
      itemData.averagePrice,
      itemData?.fee,
      itemData.duration,
      itemData.submit,
      reverse
    ]
  )

  return (
    <>
      {itemData?.active !== undefined &&
        listItemData.map(({ width, content, number, last }, key) => (
          <ListItem key={key} width={widthArr[key]} last={last ?? undefined}>
            {/* <NumberWrapper>{number && (itemData?.active ? number : 0)}</NumberWrapper> */}
            {!last ? (
              <>
                {number && <NumberWrapper>{number || 0}</NumberWrapper>}
                {content}
              </>
            ) : (
              <ExternalLink
                style={{ display: 'inline-block', color: '#3680E7' }}
                href={chainId && number ? getEtherscanLink(chainId, number as any, 'block') : ''}
              >
                {number}
              </ExternalLink>
            )}
          </ListItem>
        ))}
    </>
  )
}

function numberToFixed(num: number, decimal: number) {
  let res = num / 10 ** decimal
  return res.toFixed(res > 1 ? 2 : 4)
}

interface Props {
  orderListData: ListItemProps[]
  chainId?: ChainId | undefined
  setIsReverse?: any
  isReverse?: boolean
}

export default function CompletedOrderList({ orderListData, chainId, isReverse, setIsReverse }: Props) {
  const listTitleData = [
    {
      name: 'ID',
      width: 1
    },
    {
      name: 'Sell',
      width: 1
    },
    {
      name: 'Buy',
      width: 1
    },
    {
      name: (
        <div>
          Sell Rate
          <div>(per block)</div>
        </div>
      ),
      width: 2
    },
    {
      name: 'Sold',
      width: 2
    },
    {
      name: 'Purchased',
      width: 1
    },
    {
      name: (
        <div>
          Average Price
          <SwapOutlined
            style={{ marginLeft: '6px', cursor: 'pointer', color: isReverse ? '#3680E7' : undefined }}
            onClick={() => {
              setIsReverse(!isReverse)
            }}
          />
        </div>
      ),
      width: 1
    },
    {
      name: 'Fee',
      width: 1
    },
    {
      name: (
        <div>
          Duration
          <div>(blocks)</div>
        </div>
      ),
      width: 1
    },
    {
      name: (
        <div>
          Submit Time
          <div>(block height)</div>
        </div>
      ),
      width: 1,
      last: true
    }
  ]

  return (
    <ListWrapper>
      {/* <SwapLongTermHeader title={'Orders Completed'} /> */}
      {/* <div style={{ marginBottom: '20px' }}></div> */}
      <TableHeaderWrapper>
        {listTitleData.map((item, i) => (
          <TableHeader title={item.name} width={widthArr[i]} last={item.last} key={i} />
        ))}
      </TableHeaderWrapper>

      {orderListData
        .filter(e => !e.active)
        .map((item: ListItemProps, i: number) => (
          <ListItemWrapper index={i} key={i}>
            <TableContent itemData={item} width={widthArr[i]} chainId={chainId} reverse={isReverse} />
          </ListItemWrapper>
        ))}
    </ListWrapper>
  )
}
