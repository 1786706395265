import { useActiveWeb3React } from 'hooks'

export const useTokenRename = () => {
  const { chainId } = useActiveWeb3React()
  const checkMantleBase = chainId === 5000 || chainId === 5001

  const tokenRename = (tName: any) => {
    if (checkMantleBase) {
      if (tName === 'ETH') return 'MNT'
      // if (tName === 'WETH') return 'WMNT'
    }
    return tName
  }
  return tokenRename
}
