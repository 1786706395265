// import { BigNumber } from '@ethersproject/bignumber'
// import { Token, TokenAmount } from '@uniswap/sdk'
import { Currency, Pair } from '@uniswap/sdk'
import { useRouterContract, usePairContract } from '../hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData, Result } from '../state/multicall/hooks'
import { TWAMM_ADDRESS, useTWAMM_ADDRESS } from '../constants'
import { getRouterContractShort, getPairContractConfirmed } from '../utils'
import { useMemo } from 'react'
import { useActiveWeb3React } from '../hooks'
import {
  PairState,
  usePair,
  usePairAddress,
  useExecuteVirtualOrdersInterface,
  useExecuteVirtualOrdersInterfaceV2,
  isZero
} from './Reserves'
import { BigNumber, utils } from 'ethers'
import { useBlockNumber } from 'state/application/hooks'

// interface Props {
//   Pair?: Pair | null
//   orderId?: string
//   txnId?: string
// } //minor

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function usePairOrderIdDetails(Pair: Pair | null, orderIds: BigNumber[]): Result | undefined {
  // const contract = useRouterContract(TWAMM_ADDRESS, false)

  const { account, chainId, library } = useActiveWeb3React()
  let pairAddr = usePairAddress(Pair?.token0, Pair?.token1)

  const should_call = !!Pair && !isZero(pairAddr) && !isZero(Pair.token0) && !isZero(Pair.token1)

  const orderDetails = useSingleContractMultipleData(
    library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getOrderDetails',
    [orderIds],
    undefined,
    should_call
  )

  return useMemo(() => {
    return orderDetails[0]?.result
  }, [orderDetails])
}

export function useTokensOrderIdDetails(
  tokenA: Currency | undefined,
  tokenB: Currency | undefined,
  orderIds: BigNumber[] | undefined
): Result | undefined {
  const { account, chainId, library } = useActiveWeb3React()
  let pairAddr = usePairAddress(tokenA, tokenB)

  const should_call = pairAddr && !isZero(pairAddr[0]) && !!orderIds
  const orderDetails_res = useSingleContractMultipleData(
    should_call && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getOrderDetails',
    orderIds ? orderIds.map(e => [e.toNumber()]) : [],
    undefined,
    should_call
  )

  return useMemo(() => {
    if (!should_call || !orderDetails_res || orderDetails_res.some(e => e.loading || e.error)) return
    return orderDetails_res.map(e => e.result)
  }, [orderDetails_res, should_call])
}

export function useOrderIdDetails(orderId?: string): any | undefined {
  const contract = useRouterContract(useTWAMM_ADDRESS(), false)

  const inputs = useMemo(() => [orderId], [orderId])

  // if (1 === 1) {

  //   throw new Error('abort')
  // }
  const orderDetails: any | undefined = useSingleCallResult(contract, 'getOrderDetails', inputs)?.result?.[0]
  return orderDetails ? orderDetails : undefined
}

export function useOrderWithdrawableProceeds(
  Pair: Pair | null,
  orderId: String,
  pairStateChecked: Boolean
): [BigNumber, BigNumber, BigNumber] | undefined {
  const { account, chainId, library } = useActiveWeb3React()
  const inputs = useMemo(() => [orderId], [orderId])

  let currentBlockNumber = useBlockNumber()
  let withdrawableProceeds: BigNumber
  let averagePrice: BigNumber
  let fee: BigNumber

  const orderDetailsResults = usePairOrderIdDetails(Pair, [BigNumber.from(orderId)])
  const pairAddr = usePairAddress(Pair?.token0, Pair?.token1)
  const should_call = !!Pair && !isZero(pairAddr) && !isZero(Pair.token0) && !isZero(Pair.token1)

  let id: BigNumber
  let submitBlock: BigNumber
  let expirationBlock: BigNumber
  let saleRate: BigNumber
  let sellAmount: BigNumber
  let buyAmount: BigNumber
  let owner: String
  let sellTokenId: String
  let buyTokenId: String

  if (!!orderDetailsResults) {
    ;[
      id,
      submitBlock,
      expirationBlock,
      saleRate,
      sellAmount,
      buyAmount,
      owner,
      sellTokenId,
      buyTokenId
    ] = orderDetailsResults
  } else {
    ;[id, submitBlock, expirationBlock, saleRate, sellAmount, buyAmount, owner, sellTokenId, buyTokenId] = [
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      '0x0000000000000000000000000000000000000000',
      '0x0000000000000000000000000000000000000000',
      '0x0000000000000000000000000000000000000000'
    ]
  }

  const orderRewardFactor = useSingleContractMultipleData(
    library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getOrderRewardFactor',
    [[BigNumber.from(orderId)]],
    undefined,
    should_call //&& !!orderDetailsResults
  )

  // let [reserveA, reserveB, lastVirtualOrderBlock, currentSalesRateA, currentSalesRateB, rewardFactorA, rewardFactorB]

  const orderInfoAfterExpiration = useExecuteVirtualOrdersInterface(
    [expirationBlock?.toNumber() ?? 0],
    Pair,
    pairStateChecked
  )

  const orderInfoCurrentBlock = useExecuteVirtualOrdersInterface([currentBlockNumber ?? 0], Pair, pairStateChecked)[0]

  return useMemo(() => {
    // const fullReturnCondition = should_call

    if (
      !currentBlockNumber ||
      !orderRewardFactor ||
      !orderRewardFactor[0]?.result ||
      orderRewardFactor.some(res => res?.loading)
    ) {
      return undefined
    } else {
      const tokenAAddr = sellTokenId < buyTokenId ? sellTokenId : buyTokenId
      const { orderRewardFactorAtSubmission, orderRewardFactorAtExpiring } = orderRewardFactor[0]?.result

      let [, , lastVirtualOrderBlock, , , , ,] = orderInfoCurrentBlock //useExecuteVirtualOrdersInterface(expirationBlock ?? 0, Pair, pairState === PairState.EXISTS)

      if (expirationBlock < lastVirtualOrderBlock) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        withdrawableProceeds = orderRewardFactorAtExpiring
          .sub(orderRewardFactorAtSubmission)
          .mul(saleRate)
          .div(10000)

        let sold = ((expirationBlock.toNumber() - submitBlock.toNumber()) * saleRate.toNumber()) / 10000
        // eslint-disable-next-line react-hooks/exhaustive-deps
        averagePrice = withdrawableProceeds.add(buyAmount).div(sold)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fee = withdrawableProceeds
          .add(buyAmount)
          .mul(3)
          .div(997)
      } else if (currentBlockNumber >= expirationBlock.toNumber() && expirationBlock >= lastVirtualOrderBlock) {
        let [, , , , , rewardFactorA, rewardFactorB] = orderInfoAfterExpiration //useExecuteVirtualOrdersInterface(expirationBlock ?? 0, Pair, pairState === PairState.EXISTS)

        if (sellTokenId == tokenAAddr) {
          withdrawableProceeds = rewardFactorA
            .sub(orderRewardFactorAtSubmission[0]?.result)
            .mul(saleRate)
            .div(10000)
        } else {
          withdrawableProceeds = rewardFactorB
            .sub(orderRewardFactorAtSubmission[0]?.result)
            .mul(saleRate)
            .div(10000)
        }
        let sold = ((expirationBlock.toNumber() - submitBlock.toNumber()) * saleRate.toNumber()) / 10000
        averagePrice = withdrawableProceeds.add(buyAmount).div(sold)
        fee = withdrawableProceeds
          .add(buyAmount)
          .mul(3)
          .div(997)
      } else {
        let [, , , , , rewardFactorA, rewardFactorB] = orderInfoCurrentBlock

        if (sellTokenId == tokenAAddr) {
          withdrawableProceeds = rewardFactorA
            .sub(orderRewardFactorAtSubmission[0]?.result)
            .mul(saleRate)
            .div(10000)
        } else {
          withdrawableProceeds = rewardFactorB
            .sub(orderRewardFactorAtSubmission[0]?.result)
            .mul(saleRate)
            .div(10000)
        }
        let sold = ((currentBlockNumber - submitBlock.toNumber()) * saleRate.toNumber()) / 10000
        averagePrice = withdrawableProceeds.add(buyAmount).div(sold)
        fee = withdrawableProceeds
          .add(buyAmount)
          .mul(3)
          .div(997)
      }
      return [withdrawableProceeds, averagePrice, fee]
    }
  }, [])
}

export function useOrderWithdrawableProceedsByDetails(
  Pair: Pair | null,
  // orderIds: number[],
  orderDetailsResults: any | undefined,
  orderStatesList: any | undefined,
  pairStateChecked: Boolean
): [BigNumber, BigNumber, BigNumber] | undefined {
  const { account, chainId, library } = useActiveWeb3React()
  // const inputs = useMemo(() => [orderId], [orderId])

  let currentBlockNumber = useBlockNumber()
  let withdrawableProceeds: BigNumber
  let averagePrice: BigNumber
  let fee: BigNumber

  // const orderDetailsResults = usePairOrderIdDetails(Pair, [BigNumber.from(orderId)])
  const pairAddr = Pair?.liquidityToken?.address
  // usePairAddress(Pair?.token0, Pair?.token1)
  const should_call = !!Pair && !!pairAddr && !isZero(pairAddr) && !!orderDetailsResults

  // && !isZero(Pair.token0) && !isZero(Pair.token1)

  const orderRewardFactor = useSingleContractMultipleData(
    pairAddr && library && account ? getPairContractConfirmed(pairAddr, library, account) : undefined,
    'getOrderRewardFactor',
    !!orderDetailsResults ? orderDetailsResults.map((e: any) => [e[0]?.id?.toNumber()]) : [[]],
    undefined,
    should_call
  )

  const expiriesList = useSingleContractMultipleData(
    pairAddr && library && account ? getPairContractConfirmed(pairAddr, library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    should_call
  )
  const preprocessedExpiryList = expiriesList[0]?.result as any

  const stateInfoSinceLastExecutionMap = useExecuteVirtualOrdersInterfaceV2(
    !!preprocessedExpiryList ? preprocessedExpiryList[0].map((e: BigNumber) => e?.toNumber()) : [0],
    Pair,
    pairStateChecked
  )

  return useMemo(() => {
    if (
      !currentBlockNumber ||
      !orderRewardFactor ||
      !orderRewardFactor[0]?.result ||
      orderRewardFactor.some(res => res?.loading) ||
      !orderDetailsResults ||
      !expiriesList[0]?.result ||
      Array.isArray(stateInfoSinceLastExecutionMap) ||
      expiriesList.some(res => res?.loading) ||
      !orderStatesList
    )
      return undefined
    return orderDetailsResults.map((res: any, idx: number) => {
      if (
        !orderStatesList ||
        !currentBlockNumber ||
        !orderDetailsResults ||
        !Pair ||
        !stateInfoSinceLastExecutionMap ||
        !orderStatesList[idx]
      )
        return undefined

      let decimalPrecisionA
      let decimalPrecisionB
      if (Pair.token0.address <= Pair.token1.address) {
        ;[decimalPrecisionA, decimalPrecisionB] = [Pair.token0.decimals, Pair.token1.decimals]
      } else {
        ;[decimalPrecisionA, decimalPrecisionB] = [Pair.token1.decimals, Pair.token0.decimals]
      }

      let id: BigNumber
      let submitBlock: BigNumber
      let expirationBlock: BigNumber
      let saleRate: BigNumber
      let sellAmount: BigNumber
      let buyAmount: BigNumber
      let owner: String
      let sellTokenId: String
      let buyTokenId: String

      let { orderRewardFactorAtSubmission, orderRewardFactorAtExpiring } = orderRewardFactor[idx]?.result as any

      if (!!res) {
        ;[id, submitBlock, expirationBlock, saleRate, sellAmount, buyAmount, owner, sellTokenId, buyTokenId] = res[0]
      } else {
        ;[id, submitBlock, expirationBlock, saleRate, sellAmount, buyAmount, owner, sellTokenId, buyTokenId] = [
          BigNumber.from(0),
          BigNumber.from(0),
          BigNumber.from(0),
          BigNumber.from(0),
          BigNumber.from(0),
          BigNumber.from(0),
          '0x0000000000000000000000000000000000000000',
          '0x0000000000000000000000000000000000000000',
          '0x0000000000000000000000000000000000000000'
        ]
      }

      const tokenAAddr = sellTokenId < buyTokenId ? sellTokenId : buyTokenId
      const decimalPrecision0 = Pair.token0.address === sellTokenId ? Pair.token0.decimals : Pair.token1.decimals
      const decimalPrecision1 = Pair.token0.address === buyTokenId ? Pair.token0.decimals : Pair.token1.decimals
      let [, , lastVirtualOrderBlock, , , , ,] = stateInfoSinceLastExecutionMap[currentBlockNumber.toString()]

      if (expirationBlock <= lastVirtualOrderBlock) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //withdrawableProceeds = BigNumber.from(utils.formatUnits(orderRewardFactorAtExpiring, decimalPrecision1)).sub(orderRewardFactorAtSubmission).mul(saleRate).div(10000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        withdrawableProceeds = utils
          .parseUnits(orderRewardFactorAtExpiring.toString(), decimalPrecision0 + decimalPrecision1)
          .sub(utils.parseUnits(orderRewardFactorAtSubmission.toString(), decimalPrecision0 + decimalPrecision1))
          .mul(saleRate)
          .div(10000)

        // let sold = (expirationBlock.toNumber() - submitBlock.toNumber()) * saleRate.toNumber() / 10000
        let sold = expirationBlock
          .sub(submitBlock)
          .mul(saleRate)
          .div(10000) //unit: 10**18wei
        //let sold = utils.formatUnits(expirationBlock.sub(submitBlock).mul(saleRate).div(10000), decimalPrecision0)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        averagePrice = withdrawableProceeds
          .add(
            utils.parseUnits(buyAmount.toString(), decimalPrecision0 * 2 + 18 + decimalPrecision1 - decimalPrecision0)
          )
          .div(sold)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fee = withdrawableProceeds
          .add(
            utils.parseUnits(buyAmount.toString(), decimalPrecision0 * 2 + 18 + decimalPrecision1 - decimalPrecision0)
          )
          .mul(3)
          .div(997)
      } else if (currentBlockNumber > expirationBlock.toNumber() && expirationBlock > lastVirtualOrderBlock) {
        if (!stateInfoSinceLastExecutionMap[expirationBlock.toString()]) return undefined

        let [, , , , , rewardFactorA, rewardFactorB] = stateInfoSinceLastExecutionMap[expirationBlock.toString()]
        if (sellTokenId == tokenAAddr) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          withdrawableProceeds = rewardFactorA.gt(BigNumber.from(0))
            ? rewardFactorA
                .sub(utils.parseUnits(orderRewardFactorAtSubmission.toString(), decimalPrecision0 + decimalPrecision1))
                .mul(saleRate)
                .div(10000)
            : BigNumber.from(0)
        } else {
          withdrawableProceeds = rewardFactorB.gt(BigNumber.from(0))
            ? rewardFactorB
                .sub(utils.parseUnits(orderRewardFactorAtSubmission.toString(), decimalPrecision0 + decimalPrecision1))
                .mul(saleRate)
                .div(10000)
            : BigNumber.from(0)
        }
        let sold = expirationBlock
          .sub(submitBlock)
          .mul(saleRate)
          .div(10000)

        averagePrice = withdrawableProceeds
          .add(
            utils.parseUnits(buyAmount.toString(), decimalPrecision0 * 2 + 18 + decimalPrecision1 - decimalPrecision0)
          )
          .div(sold)

        fee = withdrawableProceeds
          .add(
            utils.parseUnits(buyAmount.toString(), decimalPrecision0 * 2 + 18 + decimalPrecision1 - decimalPrecision0)
          )
          .mul(3)
          .div(997)
      } else {
        let [, , , , , rewardFactorA, rewardFactorB] = stateInfoSinceLastExecutionMap[currentBlockNumber.toString()]

        if (sellTokenId == tokenAAddr) {
          withdrawableProceeds = rewardFactorA
            .sub(utils.parseUnits(orderRewardFactorAtSubmission.toString(), decimalPrecision0 + decimalPrecision1))
            .mul(saleRate)
            .div(10000)
        } else {
          withdrawableProceeds = rewardFactorB
            .sub(utils.parseUnits(orderRewardFactorAtSubmission.toString(), decimalPrecision0 + decimalPrecision1))
            .mul(saleRate)
            .div(10000)
        }
        let sold = BigNumber.from(currentBlockNumber)
          .sub(submitBlock)
          .mul(saleRate)
          .div(10000)
        averagePrice = sold.gt(BigNumber.from(0))
          ? withdrawableProceeds
              .add(
                utils.parseUnits(
                  buyAmount.toString(),
                  decimalPrecision0 * 2 + 18 + decimalPrecision1 - decimalPrecision0
                )
              )
              .div(sold)
          : BigNumber.from(0)

        fee = withdrawableProceeds
          .add(
            utils.parseUnits(buyAmount.toString(), decimalPrecision0 * 2 + 18 + decimalPrecision1 - decimalPrecision0)
          )
          .mul(3)
          .div(997)
      }

      return [withdrawableProceeds, averagePrice, fee]
    })
  }, [should_call, Pair])
}

export function useOrderIdStatusCheck(
  Pair: Pair | null,
  orderId: any[][] | undefined,
  pairStateChecked: Boolean
): Boolean[] | undefined {
  const { account, chainId, library } = useActiveWeb3React()

  const pairAddr = Pair?.liquidityToken?.address
  const should_call = !!Pair && !!pairAddr && !isZero(pairAddr) && !!orderId
  const orderStatus = useSingleContractMultipleData(
    pairAddr && library && account ? getPairContractConfirmed(pairAddr, library, account) : undefined,
    'orderIdStatusCheck',
    !!orderId ? orderId.map((e: any) => [e[0]?.id?.toNumber()]) : [[]],
    undefined,
    should_call && !!pairStateChecked
  )
  orderStatus
  return useMemo(() => {
    if (!orderStatus || orderStatus.some(e => e.loading) || !Pair || !orderId || !pairStateChecked) return
    return orderStatus.map(e => !!e?.result?.[0])
  }, [Pair, orderId, orderStatus, pairStateChecked])
}
