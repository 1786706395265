import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Settings from '../Settings'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const StyledSwapHeader = styled.div<{ isMax?: boolean; noPadding?: boolean }>`
  ${({ noPadding }) => (noPadding ? `` : `padding: 12px 1rem 0px 1.5rem;`)}
  margin-bottom: -4px;
  width: 100%;
  ${({ isMax }) => (isMax ? `` : `max-width: 420px;`)}
  color: ${({ theme }) => theme.text2};
`

interface Props {
  title: string | ReactElement
  settingFlag?: boolean
  isMax?: boolean
  noPadding?: boolean
  showSlippage?: boolean
  loading?: boolean
}

export default function SwapLongTermHeader({ title, settingFlag = false, isMax, noPadding, loading = false }: Props) {
  return (
    <StyledSwapHeader isMax={isMax} noPadding={noPadding}>
      <RowBetween>
        <TYPE.black fontWeight={700}>{title}</TYPE.black>
        {settingFlag ? <Settings showSlippage={false} /> : <></>}
        {loading && (
          <RowBetween maxWidth={loading ? '15px' : '0'}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />
          </RowBetween>
        )}
      </RowBetween>
    </StyledSwapHeader>
  )
}
