import { Currency, Pair } from '@uniswap/sdk'
import { useRouterContract, usePairContract } from '../hooks/useContract'
import { useSingleCallResult, useSingleContractMultipleData } from '../state/multicall/hooks'
import { TWAMM_ADDRESS } from '../constants'
import { getRouterContractShort, getPairContractConfirmed } from '../utils'
import { useMemo } from 'react'
import { useActiveWeb3React } from '../hooks'
import { PairState, usePair, usePairAddress, useExecuteVirtualOrdersInterface } from './Reserves'
import { CallState } from '../state/multicall/hooks'

// interface Props {
//   Pair?: Pair | null
// }

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export const useOrderIds = (tokenA: Currency | undefined, tokenB: Currency | undefined): any | undefined => {
  // const contract = useRouterContract(TWAMM_ADDRESS, false)

  const { account, chainId, library } = useActiveWeb3React()
  let pairAddr = usePairAddress(tokenA, tokenB)

  // const inputs = useMemo(() => [account], [account])
  // const transactionIds: [any | undefined][] = useSingleCallResult(contract, 'userIdsCheck', inputs)?.result?.[0]
  // if (inputs !== null && inputs !== undefined) {
  // }

  const transactionIds_res: CallState[] = useSingleContractMultipleData(
    library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'userIdsCheck',
    [[account ?? undefined]],
    undefined
  )

  return useMemo(() => {
    if (!transactionIds_res || transactionIds_res.length === 0) return
    const { error, result, loading } = transactionIds_res[0]
    if (!result) return
    return result[0]
  }, [transactionIds_res])
}
