import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
// import { JSBI, Percent, Router, SwapParameters, Trade, TradeType } from '@uniswap/sdk'
import { Currency, JSBI, Percent, SwapParameters, Trade, TradeType, ETHER } from '@uniswap/sdk'
import { useMemo } from 'react'
import { BIPS_BASE, DEFAULT_DEADLINE_FROM_NOW, INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import { getTradeVersion, useV1TradeExchangeAddress } from '../data/V1'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin, getRouterContract, isAddress, shortenAddress, getTWAMMContract } from '../utils'
// import isZero from '../utils/isZero'
import { isZero } from '../data/Reserves'
import v1SwapArguments from '../utils/v1SwapArguments'
import { useActiveWeb3React } from './index'
import { useV1ExchangeContract } from './useContract'
import useENS from './useENS'
import { Version } from './useToggledVersion'
import { Field } from '../state/swap/actions'
import useTransactionDeadline from './useTransactionDeadline'
import { useTimestampFromBlock } from './useTimestampFromBlock'

// import { useSwapState } from '../state/swap/hooks'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}
// interface SwapParametersTMP {
//   /**
//    * The method to call on the TWAMM.
//    */
//   methodName: string;
//   /**
//    * The arguments to pass to the method, all hex encoded.
//    */
//   args: (string | string[])[];
//   /**
//    * The amount of wei to send in hex.
//    */
//   value: string;
// }

interface SwapCall {
  contract: Contract
  parameters: SwapParameters
}

interface SuccessfulCall {
  call: SwapCall
  gasEstimate: BigNumber
}

interface FailedCall {
  call: SwapCall
  error: Error
}

type EstimatedSwapCall = SuccessfulCall | FailedCall

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param deadline the deadline for the trade
 * @param recipientAddressOrName
 */
function useSwapCallArguments(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  deadline: number = DEFAULT_DEADLINE_FROM_NOW, // in seconds from now
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  currencyInput: Currency | undefined, // TODO change to add input currencyname
  longTermWindow: number | null
): SwapCall[] {
  const { account, chainId, library } = useActiveWeb3React()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  const v1Exchange = useV1ExchangeContract(useV1TradeExchangeAddress(trade), true)

  return useMemo(() => {
    const tradeVersion = getTradeVersion(trade)
    if (!trade || !recipient || !library || !account || !tradeVersion || !chainId) return []

    const contract: Contract | null =
      tradeVersion === Version.v2 ? getRouterContract(chainId, library, account) : v1Exchange
    if (!contract) {
      return []
    }

    const swapMethods = []
    switch (tradeVersion) {
      case Version.v2:
        if (trade.tradeType === TradeType.EXACT_INPUT) {
          swapMethods.push(({
            methodName: currencyInput?.name === 'WETH' ? 'longTermSwapFromBToA' : 'longTermSwapFromAToB',
            args: [trade.inputAmount.raw.toString(), longTermWindow]
          } as unknown) as SwapParameters)
        }

        if (trade.tradeType === TradeType.EXACT_OUTPUT) {
          swapMethods.push(({
            methodName: currencyInput?.name === 'WETH' ? 'longTermSwapFromAToB' : 'longTermSwapFromBToA',
            args: [trade.inputAmount.raw.toString(), longTermWindow]
          } as unknown) as SwapParameters)
        }

        break
      case Version.v1:
        swapMethods.push(
          v1SwapArguments(trade, {
            allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            deadline
          })
        )
        break
    }
    return swapMethods.map(parameters => ({ parameters, contract }))
  }, [
    account,
    allowedSlippage,
    chainId,
    deadline,
    library,
    recipient,
    trade,
    v1Exchange,
    currencyInput,
    longTermWindow
  ]) //TODO: add currencyINPUT
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapLongTermCallback(
  trade: any | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  // deadline: number = DEFAULT_DEADLINE_FROM_NOW, // in seconds from now
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  currencies: {
    INPUT?: Currency | undefined
    OUTPUT?: Currency | undefined
  },
  blockValue: string | number | null | undefined,
  longTermWindow: number | null // used to set longterm window for long term swap
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const checkMantleBase = chainId === 5000 || chainId === 5001

  const contract = getTWAMMContract(chainId, library, account)
  const deadline = useTransactionDeadline()
  const currentTime = useTimestampFromBlock(library?.blockNumber) || deadline

  const swapCalls = useMemo(() => {
    let methodName, args, value

    methodName = 'longTermSwapTokenToToken'
    args = [
      trade?.pair?.token0?.address,
      trade?.pair?.token1?.address,
      trade?.inputAmount?.raw?.toString(),
      blockValue?.toString(),
      Number(currentTime?.toString() || new Date().getTime()) + 5000
    ]
    value = ''

    if (currencies && currencies[Field.INPUT] === ETHER && trade?.inputAmount) {
      methodName = 'longTermSwapETHToToken'
      args = [
        trade?.pair?.token1?.address,
        trade?.inputAmount?.raw?.toString(),
        blockValue?.toString(),
        deadline?.toString()
      ]
      value = BigNumber.from(trade?.inputAmount?.raw?.toString())
    } else if (currencies && currencies[Field.OUTPUT] === ETHER) {
      methodName = 'longTermSwapTokenToETH'
      args = [
        trade?.pair?.token0?.address,
        trade?.inputAmount?.raw?.toString(),
        blockValue?.toString(),
        deadline?.toString()
      ]
    }

    return [
      {
        parameters: {
          methodName,
          args,
          value
        },
        contract
      }
    ]
  }, [contract, deadline, currentTime, trade, currencies, blockValue])

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      } else {
        return { state: SwapCallbackState.LOADING, callback: null, error: null }
      }
    }

    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        // @ts-ignore
        const estimatedCalls: EstimatedSwapCall[] = await Promise.all(
          swapCalls.map(call => {
            const {
              parameters: { methodName, args, value },
              contract
            } = call
            const options = !value || isZero(value) ? {} : { value }

            return contract?.estimateGas[methodName](...args, options)

              .then(gasEstimate => {
                return {
                  call,
                  gasEstimate
                }
              })
              .catch(gasError => {
                console.debug('Gas estimate failed, trying eth_call to extract error', call)

                return contract.callStatic[methodName](...args)
                  .then(result => {
                    console.debug('Unexpected successful call after failed estimate gas', call, gasError, result)
                    return { call, error: new Error('Unexpected issue with estimating the gas. Please try again.') }
                  })
                  .catch(callError => {
                    console.debug('Call threw error', call, callError)
                    let errorMessage: string
                    console.log('error reason', callError.reason)
                    switch (callError.reason) {
                      case 'TWAMM: INSUFFICIENT_OUTPUT_AMOUNT':
                      case 'TWAMM: EXCESSIVE_INPUT_AMOUNT':
                        errorMessage =
                          'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.'
                        break
                      default:
                        errorMessage = `The transaction cannot succeed due to error: ${callError.reason}. This is probably an issue with one of the tokens you are swapping.`
                    }
                    console.log('this error')
                    return { call, error: new Error(errorMessage) }
                  })
              })
          })
        )
        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        const successfulEstimation = estimatedCalls.find(
          (el, ix, list): el is SuccessfulCall =>
            'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1])
        )

        if (!successfulEstimation) {
          const errorCalls = estimatedCalls.filter((call): call is FailedCall => 'error' in call)
          if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
          throw new Error('Unexpected error. Please contact support: none of the calls threw an error')
        }

        const {
          call: {
            contract,
            parameters: { methodName, args, value }
          },
          gasEstimate
        } = successfulEstimation

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          ...(value && !isZero(value) ? { value, from: account } : { from: account })
        })
          .then((response: any) => {
            const inputSymbol = checkMantleBase
              ? trade.inputAmount.currency.symbol === 'ETH'
                ? 'MNT'
                : trade.inputAmount.currency.symbol
              : trade.inputAmount.currency.symbol
            const outputSymbol = checkMantleBase
              ? trade.outputAmount.currency.symbol === 'ETH'
                ? 'MNT'
                : trade.outputAmount.currency.symbol
              : trade.outputAmount.currency.symbol
            const inputAmount = trade.inputAmount.toSignificant(4)
            const outputAmount = trade.outputAmount.toSignificant(4)
            const blockValueRes = Number(blockValue) * 5

            const base = `Term Swap ${inputAmount} ${inputSymbol} for ${outputSymbol} Expires in ${blockValueRes} Blocks`

            addTransaction(response, {
              // summary: withVersion
              summary: base
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error, methodName, args, value)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null
    }
  }, [
    trade,
    library,
    account,
    chainId,
    recipient,
    recipientAddressOrName,
    swapCalls,
    checkMantleBase,
    blockValue,
    addTransaction
  ])
}
