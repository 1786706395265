import React, { ReactElement, useState, useMemo } from 'react'
//import { sqrt, abs } from 'mathjs'
import styled from 'styled-components'
import { AutoRow, RowBetween } from '../../components/Row'
import { Currency, currencyEquals, ETHER, Percent, Token } from '@uniswap/sdk'
import { WETH } from 'constants/token'
import { ExternalLink, X } from 'react-feather'
import PIConfirm from '../../components/Modal/PIConfirm'
import { backgrounds, darken } from 'polished'
import SwapLongTermHeader from '../../components/swap/SwapLongTermHeader'
import { useDerivedSwapInfo } from '../../state/swap/hooks'
import { Field } from '../../state/swap/actions'
import {
  useTokensOrderIdDetails,
  useOrderWithdrawableProceedsByDetails,
  useOrderIdStatusCheck
} from '../../data/orderIdDetails'
import { useOrderIds } from '../../data/orderIds'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import {
  PairState,
  usePair,
  usePairAddress,
  useExecuteVirtualOrdersInterface,
  isZero,
  usePairBalance,
  useExecuteVirtualOrdersInterfaceV2
} from '../../data/Reserves'
import { useBlockNumber } from 'state/application/hooks'
import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
import { utils } from 'ethers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useActiveWeb3React } from '../../hooks'
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getPairContractConfirmed,
  getRouterContract,
  getTWAMMContract
} from '../../utils'
import { TransactionResponse } from '@ethersproject/providers'
import { Select, Divider, Space, Spin } from 'antd'
import { HLayoutWrapper, OrderWrapper } from './SwapLongTermPageBody'
import CompletedOrderList from './CompletedOrderList'
import { Collapse, Badge, Popover } from 'antd'
import { SwapOutlined, LoadingOutlined } from '@ant-design/icons'
import { useSingleContractMultipleData } from 'state/multicall/hooks'

const { Panel } = Collapse

const { Option } = Select

const ListWrapper = styled.div`
  /* padding: 8px 0 15px 0; */
  width: 100%;
`

const commonStyle = styled.div`
  min-width: 80px;
`
const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    padding-left: 0.2rem !important;
  }
`

const NumberWrapper = styled.span`
  font-size: 0.8rem;
  margin-right: 5px;
  display: inline-block;
`

const PanelWrapper = styled(Panel)`
  .ant-collapse-content-box {
    max-height: 50vh;
    overflow: auto;
  }
`

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  text-align: center;

  padding: 0px 1.5rem 0px 1.5rem;
  width: 100%;
  /* min-width: 100px; */
  color: ${({ theme }) => theme.text5};

  position: sticky;
  top: -16px;
  background: #ffff;
`

const TableTitle = styled(commonStyle)<{ width?: number; last?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  /* flex: ${({ width }) => (width ? 1 : '100px')}; */
  flex: ${({ width }) => width || '100px'};

  border-right: ${({ last }) => (last ? '' : '2px dashed #c4c4c4')};

  height: 60px;
  color: ${({ theme }) => theme.tableTitleTextColor1};
  font-size: 14px;
`

const widthArr = [0.1, 0.1, 0.1, 2, 2, 2, 2.5, 2, 1.5, 2.5]

const TableHeader = function({
  title,
  width,
  last
}: {
  title: string | number | null | ReactElement
  width: number
  last: boolean | undefined
}) {
  return (
    <TableTitle width={width} last={last}>
      {title}
    </TableTitle>
  )
}

const ListItemWrapper = styled.div<{ index: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0px 1.5rem 0px 1.5rem;
  width: 100%;
  color: ${({ theme }) => theme.text5};
  background-color: ${({ theme, index }) => (index % 2 === 0 ? theme.customLightBlue1 : '')};
  text-align: center;
`

const ListItem = styled(commonStyle)<{ width?: number; last?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  /* flex: ${({ width }) => (width ? 1 : '100px')}; */
  flex: ${({ width }) => width || '100px'};

  border-right: ${({ last }) => (last ? '' : '2px dashed #c4c4c4')};

  height: 60px;
  /* min-width: ${({ width }) => {
    return '100px'
  }}; */
  color: ${({ theme }) => theme.text1};
  font-size: 12px;
  flex-wrap: wrap;
`

const XIconWrapper = styled.div<{ bg?: string; disable?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  width: 40px;
  height: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  #svg-box {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    ${({ theme }) => theme.flexColumnNoWrap};

    &:hover {
      background-color: ${({ theme }) => darken(0.03, theme.primary5)};
    }
    &:active {
      box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary5)};
      background-color: ${({ theme }) => darken(0.05, theme.primary5)};
    }

    background-color: ${({ theme, bg }) => (bg ? bg : theme.lightBlue)};
    background-image: ${({ theme, bg }) => (bg ? bg : theme.lightBlue)};
    color: ${({ theme }) => theme.white};

    ${({ disable }) => disable && `opacity:.4;cursor: not-allowed;`}
  }
  /* background-color:red;background-image:none; */

  #show-note {
    padding: 2px 8px;
    opacity: 0;
    transition: 0.35;
    font-size: 14px;
    background-color: ${({ theme }) => theme.black};
    display: block;
    position: absolute;
    margin-top: -70px;
    border-radius: 2px;
  }

  &:hover #show-note {
    color: ${({ theme }) => theme.white};
    opacity: 0.65;
  }
`

interface ListItemProps {
  id?: string | number
  sell?: string | number
  buy?: string | number
  unSold?: string | number
  sellRate?: string | number
  unWithdraw?: string | number
  averagePrice?: string | number
  fee?: string | number
  timeLeft?: string | number
  last?: boolean | undefined
  token0?: Token | undefined
  token1?: Token | undefined
  number?: number | undefined
  active?: boolean | undefined
  reverse?: boolean | undefined
}

const TableContent = function({
  itemData,
  width,
  reverse
}: {
  itemData: ListItemProps
  width: number | undefined
  reverse: boolean | undefined
}) {
  // modal and loading
  const [{ showConfirm, content }, setConfirmState] = useState<{
    showConfirm: boolean
    content?: ReactElement
  }>({
    showConfirm: false,
    content: undefined
  })
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const { account, chainId, library } = useActiveWeb3React()
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [selectItems, setSelectItems] = useState<any>([])
  const [selectedFn, setSelectedFn] = useState<string>()
  const tokenA = itemData?.token0
  const tokenB = itemData?.token1
  const checkMantleBase = chainId === 5000 || chainId === 5001

  // tx sending
  const addTransaction = useTransactionAdder()
  async function onFnCall(fnName: string, args: Array<string | string[] | number | boolean>, summary: string) {
    if (!chainId || !library || !account || !deadline) throw new Error('missing dependencies')
    const router = getTWAMMContract(chainId, library, account)

    const currencyBIsETH = itemData?.token0 === ETHER
    const oneCurrencyIsETH = itemData?.token1 === ETHER || currencyBIsETH

    if (!router || !itemData || !tokenA?.address || !tokenB?.address || !itemData.id) return
    let estimate, method: (...args: any) => Promise<TransactionResponse>, value: BigNumber | null, methodNames: string
    // args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    // removeLiquidityETH
    // if (oneCurrencyIsETH) {
    // }
    // // removeLiquidity
    // else {
    //   methodNames = ['withdrawLiquidity']
    //   args = [tokenA.address, tokenB.address, liquidityAmount.raw.toString(), 0, 0, deadline.toHexString()]
    // }
    // else {
    //   throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    // }
    // estimate = router.estimateGas.withdrawProceedsFromTermSwapETHToToken
    // method = router.withdrawProceedsFromTermSwapETHToToken
    // // methodNames = 'withdrawProceedsFromTermSwapTokenToToken'
    // methodNames = 'withdrawProceedsFromTermSwapTokenToToken'
    // args = [tokenB.address, itemData.id, deadline.toString()]
    estimate = router.estimateGas[fnName]
    method = router[fnName]
    // methodNames = 'withdrawProceedsFromTermSwapTokenToToken'
    // methodNames = 'withdrawProceedsFromTermSwapTokenToToken'
    // args = [tokenA.address, tokenB.address, itemData.id, deadline.toString()]

    // value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    value = null

    setAttemptingTxn(true)
    await estimate(
      ...args
      // , value ? { value } : {}
    ).then(estimatedGasLimit =>
      method(...args, {
        // ...(value ? { value } : {}),
        gasLimit: calculateGasMargin(estimatedGasLimit)
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary
          })

          setTxHash(response.hash)

          // ReactGA.event({
          //   category: 'Liquidity',
          //   action: 'Remove',
          //   label: [currencyA?.symbol, currencyB?.symbol].join('/')
          // })
        })
        .catch((error: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error)
        })
    )
  }

  // const onWithdraw = () => {
  //   console.log(itemData)

  //   setConfirmState({ showConfirm: true })
  // }

  const onCancel = () => {
    console.log(itemData)

    setConfirmState({ showConfirm: true })
  }

  const listItemData = useMemo(
    () => [
      {
        width,
        content: itemData.id
      },
      {
        width,
        content: itemData.sell
      },
      {
        width,
        content: itemData.buy
      },
      {
        width,
        content: ' ' + itemData.sell,
        number: itemData.sellRate
      },
      {
        width,
        content: ' ' + itemData.sell,
        number: itemData.unSold
      },
      {
        width,
        content: ' ' + itemData.buy,
        number: itemData.unWithdraw
      },
      {
        width,
        content: reverse ? ' ' + itemData.sell + ' per ' + itemData.buy : ' ' + itemData.buy + ' per ' + itemData.sell,
        number: reverse
          ? !!itemData?.averagePrice && itemData?.averagePrice > 0
            ? (1 / Number(itemData?.averagePrice)).toFixed(1 / Number(itemData?.averagePrice) > 1 ? 4 : 6)
            : 0
          : itemData.averagePrice ?? 0
      },
      {
        width,
        content: ' ' + itemData?.buy,
        number: itemData?.fee || '0'
      },
      {
        width,
        number: itemData.timeLeft
      },
      {
        width,
        last: true,
        content: (
          <AutoRow gap="10px" justify="center">
            <XIconWrapper
              bg={'linear-gradient(90deg, #0261F5 0%, #6B5CFF 100%);'}
              disable={!itemData?.active}
              onClick={() => {
                if (!itemData?.active) return
                // || itemData?.unWithdraw === 0
                let targetArr = [{ val: 'withdrawProceedsFromTermSwapTokenToToken', name: 'Receive ' + itemData.buy }]
                if (checkMantleBase) {
                  if (itemData.buy === 'MNT') {
                    targetArr = [
                      { val: 'withdrawProceedsFromTermSwapTokenToETH', name: 'Receive MNT' },
                      { val: 'withdrawProceedsFromTermSwapTokenToWETH', name: 'Receive WMNT' }
                    ]
                  }
                } else if (itemData.buy === 'ETH') {
                  targetArr = [
                    { val: 'withdrawProceedsFromTermSwapTokenToETH', name: 'Receive ETH' },
                    { val: 'withdrawProceedsFromTermSwapTokenToWETH', name: 'Receive WETH' }
                  ]
                }

                if (checkMantleBase) {
                  if (itemData.sell === 'MNT') {
                    targetArr = [{ val: 'withdrawProceedsFromTermSwapETHToToken', name: 'Receive ' + itemData.buy }]
                  }
                } else if (itemData.sell === 'ETH') {
                  targetArr = [{ val: 'withdrawProceedsFromTermSwapETHToToken', name: 'Receive ' + itemData.buy }]
                }
                setSelectItems(targetArr)
                setSelectedFn(targetArr[0]?.val)
                setConfirmState({ showConfirm: true })
              }}
            >
              <Popover content={'Withdraw Orders'} title="" trigger="hover">
                <div id="svg-box">
                  <ExternalLink size={17} />
                </div>
              </Popover>

              {/* <div id="show-note">Withdraw Orders</div> */}
            </XIconWrapper>
            <XIconWrapper
              bg={'linear-gradient(90deg, #0261F5 0%, #6B5CFF 100%);'}
              disable={!itemData?.active}
              onClick={() => {
                if (!itemData?.active) return
                // onCancel()
                // || itemData?.unWithdraw === 0
                // if (!deadline || !tokenB || !tokenA || !itemData?.id) return
                // let args1 = [tokenA.address, tokenB.address, itemData.id, deadline.toString()]
                // onFnCall(
                //   'cancelTermSwapTokenToToken',
                //   args1,
                //   'cancel Sell' + tokenA?.symbol + ' and ' + 'buy' + tokenB?.symbol
                // )
                let targetArr = [
                  { val: 'cancelTermSwapTokenToToken', name: 'Receive ' + itemData.sell + ' and ' + itemData.buy }
                ]
                if (checkMantleBase) {
                  if (itemData.buy === 'MNT') {
                    targetArr = [
                      { val: 'cancelTermSwapTokenToETH', name: 'Receive MNT and ' + itemData.sell },
                      { val: 'cancelTermSwapTokenToWETH', name: 'Receive WMNT and ' + itemData.sell }
                    ]
                  }
                } else if (itemData.buy === 'ETH') {
                  targetArr = [
                    { val: 'cancelTermSwapTokenToETH', name: 'Receive ETH and ' + itemData.sell },
                    { val: 'cancelTermSwapTokenToWETH', name: 'Receive WETH and ' + itemData.sell }
                  ]
                }
                if (checkMantleBase) {
                  if (itemData.sell === 'MNT') {
                    targetArr = [
                      { val: 'cancelTermSwapETHToToken', name: 'Receive MNT and ' + itemData.buy },
                      { val: 'cancelTermSwapWETHToToken', name: 'Receive WMNT and ' + itemData.buy }
                    ]
                  }
                } else if (itemData.sell === 'ETH') {
                  targetArr = [
                    { val: 'cancelTermSwapETHToToken', name: 'Receive ETH and ' + itemData.buy },
                    { val: 'cancelTermSwapWETHToToken', name: 'Receive WETH and ' + itemData.buy }
                  ]
                }

                setSelectItems(targetArr)
                setSelectedFn(targetArr[0]?.val)
                setConfirmState({ showConfirm: true })
              }}
            >
              <Popover content={'Cancel Orders'} title="" trigger="hover">
                <div id="svg-box">
                  <X size={17} />
                </div>
              </Popover>

              {/* <div id="show-note">Cancel Orders</div> */}
            </XIconWrapper>
          </AutoRow>
        )
      }
    ],
    [
      checkMantleBase,
      itemData?.active,
      itemData.averagePrice,
      itemData.buy,
      itemData?.fee,
      itemData.id,
      itemData.sell,
      itemData.sellRate,
      itemData.timeLeft,
      itemData.unSold,
      itemData.unWithdraw,
      reverse,
      width
    ]
  )

  return (
    <>
      {itemData?.active !== undefined &&
        listItemData.map(({ width, content, last, number }, key) => (
          <ListItem key={key} width={widthArr[key]} last={last ?? undefined}>
            <NumberWrapper>{number && (itemData?.active ? number : 0)}</NumberWrapper>
            {content}
          </ListItem>
        ))}

      <PIConfirm
        isOpen={showConfirm}
        title="Are You Sure?"
        content={
          content ?? (
            <StyledSelect
              placeholder="select time"
              bordered={false}
              value={selectedFn}
              onChange={setSelectedFn}
              disabled={selectItems.length <= 1}
              // filterOption={(input, option) =>
              //   ((option!.children as unknown) as string).toLowerCase().includes(input.toLowerCase())
              // }
              // dropdownRender={menu => (
              //   <>
              //     {menu}
              //     <Divider style={{ margin: '0px 0' }} />
              //     <Space align="center" style={{ padding: '8px 12px 12px 12px' }}>
              //       <CustomTimeButton onClick={onCustomTimeSelect} style={{ whiteSpace: 'nowrap' }}>
              //         Custom
              //       </CustomTimeButton>
              //     </Space>
              //   </>
              // )}
              dropdownStyle={{
                borderBottomRightRadius: '16px',
                borderBottomLeftRadius: '16px'
              }}
            >
              {selectItems.map((item: any, index: any) => (
                <Option value={item.val} key={index}>
                  {item.name}
                </Option>
              ))}
            </StyledSelect>
          )
        }
        onConfirm={() => {
          console.log('Confirm')
          if (!deadline || !tokenB || !tokenA || !itemData?.id || !selectedFn || !chainId) return
          let args = [tokenA.address, tokenB.address, itemData.id, deadline.toString()]
          let functionName = selectedFn
          let prefix = 'Withdraw'
          if (selectedFn.includes('cancel')) {
            prefix = 'Cancel'
          }
          let summary = prefix + ' sell' + tokenA?.symbol + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
          if (selectedFn === 'withdrawProceedsFromTermSwapTokenToWETH') {
            functionName = 'withdrawProceedsFromTermSwapTokenToToken'
            args[1] = WETH[chainId]?.address as any
            summary = 'Withdraw sell' + tokenA?.symbol + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
          }

          if (selectedFn === 'withdrawProceedsFromTermSwapWETHtoToken') {
            functionName = 'withdrawProceedsFromTermSwapTokenToToken'
            args[1] = WETH[chainId]?.address as any
            summary = 'Withdraw sell' + tokenA?.symbol + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
          }

          if (selectedFn === 'withdrawProceedsFromTermSwapTokenToETH') {
            functionName = 'withdrawProceedsFromTermSwapTokenToETH'
            args = [tokenA.address, itemData.id, deadline.toString()]
            summary = checkMantleBase
              ? 'Withdraw sell' + tokenA?.symbol + ' and ' + 'buy' + 'MNT' + ' Order' + itemData?.id
              : 'Withdraw sell' + tokenA?.symbol + ' and ' + 'buy' + 'ETH' + ' Order' + itemData?.id
          }

          if (selectedFn === 'withdrawProceedsFromTermSwapETHToToken') {
            functionName = 'withdrawProceedsFromTermSwapETHToToken'
            args = [tokenB.address, itemData.id, deadline.toString()]
            summary = checkMantleBase
              ? 'Withdraw sell' + 'MNT' + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
              : 'Withdraw sell' + 'ETH' + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
          }

          if (selectedFn === 'cancelTermSwapTokenToETH') {
            functionName = 'cancelTermSwapTokenToETH'
            args = [tokenA.address, itemData.id, deadline.toString()]
            summary = checkMantleBase
              ? 'Cancel sell' + tokenA?.symbol + ' and ' + 'buy' + 'MNT' + ' Order' + itemData?.id
              : 'Cancel sell' + tokenA?.symbol + ' and ' + 'buy' + 'ETH' + ' Order' + itemData?.id
          }

          if (selectedFn === 'cancelTermSwapETHToToken') {
            functionName = 'cancelTermSwapETHToToken'
            args = [tokenB.address, itemData.id, deadline.toString()]
            summary = checkMantleBase
              ? 'Cancel sell' + 'MNT' + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
              : 'Cancel sell' + 'ETH' + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
          }

          if (selectedFn === 'cancelTermSwapTokenToWETH') {
            functionName = 'cancelTermSwapTokenToToken'
            args[1] = WETH[chainId]?.address as any
            summary = 'Cancel Sell' + tokenA?.symbol + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
          }

          if (selectedFn === 'cancelTermSwapWETHToToken') {
            functionName = 'cancelTermSwapTokenToToken'
            args[0] = WETH[chainId]?.address as any
            summary = 'Cancel Sell' + tokenA?.symbol + ' and ' + 'buy' + tokenB?.symbol + ' Order' + itemData?.id
          }

          onFnCall(functionName, args, summary)
          setConfirmState({ showConfirm: false })
        }}
        onDismiss={() => {
          console.log('Cancel')
          setConfirmState({ showConfirm: false })
        }}
      />
    </>
  )
}

function numberToFixed(num: number, decimal: number) {
  let res = num / 10 ** decimal
  return res.toFixed(res > 1 ? 2 : 4)
}

export default function OrderList() {
  const { currencies, inputError: swapInputError, resTrade } = useDerivedSwapInfo()
  const currentOrderIds = useOrderIds(currencies[Field.INPUT], currencies[Field.OUTPUT])
  const orderList = useTokensOrderIdDetails(currencies[Field.INPUT], currencies[Field.OUTPUT], currentOrderIds)
  const tokens = useAllTokens()
  const currentBlockNumber = useBlockNumber()
  const [pairState, pair] = usePair(currencies[Field.INPUT], currencies[Field.OUTPUT])
  const stateCheckRes: any = useOrderIdStatusCheck(pair, orderList as any, pairState === PairState.EXISTS)
  const proceedsRes: any = useOrderWithdrawableProceedsByDetails(
    pair,
    orderList as any,
    stateCheckRes,
    pairState === PairState.EXISTS
  )
  const [isReverse, setIsReverse] = useState(false)
  const { account, chainId, library } = useActiveWeb3React()
  const [isCListReverse, setIsCListReverse] = useState(false)

  const listTitleData = [
    {
      name: 'ID',
      width: 1
    },
    {
      name: 'Sell',
      width: 1
    },
    {
      name: 'Buy',
      width: 1
    },
    {
      name: (
        <div>
          Sell Rate
          <div>(per block)</div>
        </div>
      ),
      width: 2
    },
    {
      name: 'Unsold',
      width: 2
    },
    {
      name: 'Unwithdraw',
      width: 1
    },
    {
      name: (
        <div>
          Average Price
          <SwapOutlined
            style={{ marginLeft: '6px', cursor: 'pointer', color: isReverse ? '#3680E7' : undefined }}
            onClick={() => {
              setIsReverse(!isReverse)
            }}
          />
        </div>
      ),
      width: 1
    },
    {
      name: 'Fee',
      width: 1
    },
    {
      name: (
        <div>
          Time Left
          <div>(blocks)</div>
        </div>
      ),
      width: 1
    },
    {
      name: 'Actions',
      width: 1,
      last: true
    }
  ]

  const orderListData = useMemo(() => {
    const checkMantleBase = chainId === 5000 || chainId === 5001
    if (!orderList || !currentBlockNumber || !proceedsRes || !stateCheckRes) return []
    let arr = [orderList?.length, proceedsRes?.length, stateCheckRes?.length]
    if (!arr.every(el => el === arr[0])) return []
    return orderList.map((e: any, idx: number) => {
      // const { buyTokenId, expirationBlock, id, saleRate, sellTokenId } = e[0]
      const { id, submitBlock, expirationBlock, saleRate, sellAmount, buyAmount, sellTokenId, buyTokenId } = e[0]
      if (!!proceedsRes[idx]) {
        const [unWithdraw, averagePrice, fee] = proceedsRes[idx]

        // const unWithdraw: BigNumber = orderProceeds[idx]
        // const averagePrice: BigNumber = averagePriceRes[idx]
        // {
        //   id: '0001',
        //   sell: 'ETH',
        //   buy: 'DAI',
        //   sellRate: '23 ETH',
        //   unSold: '28374 ETH',
        //   unWithdraw: '21973 DAI',
        //   price: '2 DAI per ETH',
        //   fee: '3.458 DAI',
        //   timeLeft: '3094'
        // },

        let decimalPrecision = tokens[sellTokenId]?.decimals
        let decimalPrecisionSell = tokens[sellTokenId]?.decimals
        let decimalPrecisionBuy = tokens[buyTokenId]?.decimals
        let resSaleRate = parseFloat(utils.formatUnits(saleRate.div(10000), decimalPrecisionSell))
        let resUnWithdraw = parseFloat(
          utils.formatUnits(
            unWithdraw,
            decimalPrecisionBuy + decimalPrecisionSell * 2 + 18 + decimalPrecisionBuy - decimalPrecisionSell
          )
        )
        let resAveragePrice = parseFloat(
          //utils.formatUnits(averagePrice, abs(decimalPrecisionBuy * 2 - decimalPrecisionSell))
          utils.formatUnits(
            averagePrice,
            decimalPrecisionBuy + decimalPrecisionSell + 18 + decimalPrecisionBuy - decimalPrecisionSell
          )
        )

        let resFee = parseFloat(
          utils.formatUnits(
            fee,
            decimalPrecisionBuy + decimalPrecisionSell * 2 + 18 + decimalPrecisionBuy - decimalPrecisionSell
          )
        ) //TODO check

        // let resUnWithdraw = parseFloat(
        //   utils.formatUnits(utils.formatUnits(unWithdraw, decimalPrecisionBuy), decimalPrecisionSell)
        // )
        // let resAveragePrice = parseFloat(utils.formatUnits(averagePrice, decimalPrecisionBuy))
        // let resFee = parseFloat(utils.formatUnits(utils.formatUnits(fee, decimalPrecisionBuy), decimalPrecisionSell)) //TODO check
        let resUnsold = (expirationBlock?.toNumber() - currentBlockNumber) * resSaleRate

        return {
          id: id?.toString(),
          sell: checkMantleBase
            ? tokens[sellTokenId]?.symbol === 'WMNT'
              ? 'MNT'
              : tokens[sellTokenId]?.symbol
            : tokens[sellTokenId]?.symbol === 'WETH'
            ? 'ETH'
            : tokens[sellTokenId]?.symbol,
          buy: checkMantleBase
            ? tokens[buyTokenId]?.symbol === 'WMNT'
              ? 'MNT'
              : tokens[buyTokenId]?.symbol
            : tokens[buyTokenId]?.symbol === 'WETH'
            ? 'ETH'
            : tokens[buyTokenId]?.symbol,

          token0: tokens[sellTokenId],
          token1: tokens[buyTokenId],
          sellRate: resSaleRate.toFixed(resSaleRate > 1 ? 4 : 6),
          unSold: expirationBlock < currentBlockNumber ? 0 : resUnsold.toFixed(resUnsold > 1 ? 2 : 4),
          unWithdraw: unWithdraw.gt(BigNumber.from(0)) ? resUnWithdraw.toFixed(resUnWithdraw > 1 ? 2 : 4) : 0,
          averagePrice: averagePrice.gt(BigNumber.from(0)) ? resAveragePrice.toFixed(resAveragePrice > 1 ? 4 : 6) : 0,
          fee: fee.gt(BigNumber.from(0)) ? resFee.toFixed(resFee > 1 ? 4 : 6) : 0,
          timeLeft: expirationBlock < currentBlockNumber ? 0 : expirationBlock - currentBlockNumber,
          active: stateCheckRes[idx]
        }
      } else {
        //let decimalPrecision = tokens[sellTokenId]?.decimals
        let decimalPrecisionSell = tokens[sellTokenId]?.decimals
        let decimalPrecisionBuy = tokens[buyTokenId]?.decimals
        let resSaleRate = parseFloat(utils.formatUnits(saleRate.div(10000), decimalPrecisionSell))
        let resSellAmount = parseFloat(utils.formatUnits(sellAmount, decimalPrecisionSell))
        let resBuyAmount = parseFloat(utils.formatUnits(buyAmount, decimalPrecisionBuy))
        let resAveragePrice = sellAmount.gt(BigNumber.from(0)) ? resBuyAmount / resSellAmount : 0
        let resFee = parseFloat(utils.formatUnits(buyAmount.mul(3).div(997), decimalPrecisionBuy)) //TODO check

        return {
          id: id?.toString(),
          sell: checkMantleBase
            ? tokens[sellTokenId]?.symbol === 'WMNT'
              ? 'MNT'
              : tokens[sellTokenId]?.symbol
            : tokens[sellTokenId]?.symbol === 'WETH'
            ? 'ETH'
            : tokens[sellTokenId]?.symbol,
          buy: checkMantleBase
            ? tokens[buyTokenId]?.symbol === 'WMNT'
              ? 'MNT'
              : tokens[buyTokenId]?.symbol
            : tokens[buyTokenId]?.symbol === 'WETH'
            ? 'ETH'
            : tokens[buyTokenId]?.symbol,
          token0: tokens[sellTokenId],
          token1: tokens[buyTokenId],
          sellRate: resSaleRate.toFixed(resSaleRate > 1 ? 4 : 6),
          sold: resSellAmount.toFixed(resSellAmount > 1 ? 2 : 4),
          purchased: resBuyAmount.toFixed(resBuyAmount > 1 ? 2 : 4),
          averagePrice: resAveragePrice.toFixed(resAveragePrice > 1 ? 4 : 6),
          fee: resFee.toFixed(resFee > 1 ? 4 : 6),
          duration: saleRate.gt(BigNumber.from(0))
            ? sellAmount
                .mul(10000)
                .div(saleRate)
                .toNumber()
            : 0,
          submit: submitBlock,
          active: stateCheckRes[idx]
        }
      }
    })
  }, [chainId, currentBlockNumber, orderList, proceedsRes, stateCheckRes, tokens])

  const completedOrderListData = useMemo(() => {
    const checkMantleBase = chainId === 5000 || chainId === 5001
    if (!orderList || !currentBlockNumber || !stateCheckRes) return []
    let arr = [orderList?.length, stateCheckRes?.length]
    if (!arr.every(el => el === arr[0])) return []
    return orderList.map((e: any, idx: number) => {
      // const { id, submitBlock, expirationBlock, saleRate, buyAmount, sellTokenId, buyTokenId } = e[0]
      // const sellAmount = (expirationBlock.sub(submitBlock)).mul(saleRate).div(10000)
      const { id, submitBlock, expirationBlock, saleRate, sellAmount, buyAmount, sellTokenId, buyTokenId } = e[0]

      // let resSaleRate = saleRate?.toNumber() / 10000   10 ** tokens[sellTokenId]?.decimals
      //let decimalPrecision = tokens[sellTokenId]?.decimals
      let decimalPrecisionSell = tokens[sellTokenId]?.decimals
      let decimalPrecisionBuy = tokens[buyTokenId]?.decimals
      let resSaleRate = parseFloat(utils.formatUnits(saleRate.div(10000), decimalPrecisionSell))
      let resSellAmount = parseFloat(utils.formatUnits(sellAmount, decimalPrecisionSell))
      let resBuyAmount = parseFloat(utils.formatUnits(buyAmount, decimalPrecisionBuy))
      let resAveragePrice = sellAmount.gt(BigNumber.from(0)) ? resBuyAmount / resSellAmount : 0
      let resFee = parseFloat(utils.formatUnits(buyAmount.mul(3).div(997), decimalPrecisionBuy)) //TODO check

      return {
        id: id?.toString(),
        sell: checkMantleBase
          ? tokens[sellTokenId]?.symbol === 'WMNT'
            ? 'MNT'
            : tokens[sellTokenId]?.symbol
          : tokens[sellTokenId]?.symbol === 'WETH'
          ? 'ETH'
          : tokens[sellTokenId]?.symbol,
        buy: checkMantleBase
          ? tokens[buyTokenId]?.symbol === 'WMNT'
            ? 'MNT'
            : tokens[buyTokenId]?.symbol
          : tokens[buyTokenId]?.symbol === 'WETH'
          ? 'ETH'
          : tokens[buyTokenId]?.symbol,
        token0: tokens[sellTokenId],
        token1: tokens[buyTokenId],
        sellRate: resSaleRate.toFixed(resSaleRate > 1 ? 4 : 6),
        sold: resSellAmount.toFixed(resSellAmount > 1 ? 2 : 4),
        purchased: resBuyAmount.toFixed(resBuyAmount > 1 ? 2 : 4),
        averagePrice: resAveragePrice.toFixed(resAveragePrice > 1 ? 4 : 6),
        fee: resFee.toFixed(resFee > 1 ? 4 : 6),
        duration: saleRate.gt(BigNumber.from(0))
          ? sellAmount
              .mul(10000)
              .div(saleRate)
              .toNumber()
          : 0,
        submit: submitBlock,
        active: stateCheckRes[idx]
      }
    })
  }, [chainId, currentBlockNumber, orderList, stateCheckRes, tokens])

  // let currentBlockNumber = useBlockNumber()

  // const [pairState, pair] = usePair(currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined)
  // const pairAddr = usePairAddress(currencies[Field.INPUT], currencies[Field.OUTPUT])[0]
  // const userPairABalance = useTokenBalance(isZero(pairAddr) ? undefined : pairAddr, pair?.token0)
  // const userPairBBalance = useTokenBalance(isZero(pairAddr) ? undefined : pairAddr, pair?.token1)

  // const pairBalance = usePairBalance(currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined)

  const expiriesList = useSingleContractMultipleData(
    pair && library && account ? getPairContractConfirmed(pair.liquidityToken.address, library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    !!pair
  )
  const preprocessedExpiryList = expiriesList[0]?.result as any
  let reserveA
  let reserveB
  let lastVirtualOrderBlock: number
  let currentSalesRateA
  let currentSalesRateB
  let rewardFactorA
  let rewardFactorB
  ///////////////////////// below for old changes /////////////////////////////
  const stateInfoSinceLastExecutionMap = useExecuteVirtualOrdersInterfaceV2(
    !!preprocessedExpiryList ? preprocessedExpiryList[0].map((e: BigNumber) => e?.toNumber()) : [0],
    pair,
    pairState === PairState.EXISTS
  )
  if (!!currentBlockNumber && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    ;[
      reserveA,
      reserveB,
      lastVirtualOrderBlock,
      currentSalesRateA,
      currentSalesRateB,
      rewardFactorA,
      rewardFactorB
    ] = stateInfoSinceLastExecutionMap[currentBlockNumber.toString()]
  } else {
    ;[reserveA, reserveB, lastVirtualOrderBlock, currentSalesRateA, currentSalesRateB, rewardFactorA, rewardFactorB] = [
      BigNumber.from(0),
      BigNumber.from(0),
      0,
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0)
    ]
  }

  let reserveInPut
  let reserveOutPut

  if (pair && currentBlockNumber && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    if (pair.token0.address <= pair.token1.address) {
      ;[reserveInPut, reserveOutPut] = [reserveA, reserveB]
    } else {
      ;[reserveInPut, reserveOutPut] = [reserveB, reserveA]
    }
  } else {
    ;[reserveInPut, reserveOutPut] = [undefined, undefined]
  }

  //const loading = !!(pair && !reserveInPut && !reserveOutPut)

  return (
    <>
      <HLayoutWrapper>
        <OrderWrapper>
          <ListWrapper>
            <Collapse defaultActiveKey={['1']} ghost>
              <PanelWrapper
                header={
                  <SwapLongTermHeader
                    noPadding={true}
                    title={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Orders in Swap</span>
                        {orderListData.filter(e => e.active).length > 0 ? (
                          <Badge
                            className="site-badge-count-109"
                            count={orderListData.filter(e => e.active).length}
                            style={{ backgroundColor: '#3680E7', marginLeft: '20px' }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    }
                    loading={!!(pair && !reserveInPut && !reserveOutPut)}
                    //loading={!!pair && !proceedsRes}
                  />
                }
                key="1"
              >
                {/* {loading && (
                  <RowBetween maxWidth={loading ? '15px' : '0'}>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} spinning={loading} />
                  </RowBetween>
                )} */}

                <TableHeaderWrapper>
                  {listTitleData.map((item, i) => (
                    <TableHeader title={item.name} width={widthArr[i]} last={item.last} key={i} />
                  ))}
                </TableHeaderWrapper>

                {orderListData
                  .filter(e => e.active)
                  .map((item, i) => (
                    <ListItemWrapper index={i} key={i}>
                      <TableContent itemData={item} width={widthArr[i]} reverse={isReverse} />
                    </ListItemWrapper>
                  ))}
              </PanelWrapper>
            </Collapse>
            {/* <SwapLongTermHeader title={'Orders in Swap'} />
            <div style={{ marginBottom: '20px' }}></div>
            <TableHeaderWrapper>
              {listTitleData.map((item, i) => (
                <TableHeader title={item.name} width={widthArr[i]} last={item.last} key={i} />
              ))}
            </TableHeaderWrapper>

            {orderListData
              .filter(e => e.active)
              .map((item, i) => (
                <ListItemWrapper index={i} key={i}>
                  <TableContent itemData={item} width={widthArr[i]} />
                </ListItemWrapper>
              ))} */}
          </ListWrapper>
        </OrderWrapper>
      </HLayoutWrapper>

      <HLayoutWrapper>
        <OrderWrapper>
          <Collapse defaultActiveKey={['']} ghost>
            <PanelWrapper
              header={
                <SwapLongTermHeader
                  noPadding={true}
                  title={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>Orders Completed</span>
                      {completedOrderListData.filter(e => !e.active).length > 0 ? (
                        <Badge
                          className="site-badge-count-109"
                          count={completedOrderListData.filter(e => !e.active).length}
                          style={{ backgroundColor: '#3680E7', marginLeft: '20px' }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  }
                />
              }
              key="1"
            >
              <CompletedOrderList
                orderListData={completedOrderListData}
                chainId={chainId}
                isReverse={isCListReverse}
                setIsReverse={setIsCListReverse}
              ></CompletedOrderList>
              {/* <TableHeaderWrapper>
                {listTitleData.map((item, i) => (
                  <TableHeader title={item.name} width={widthArr[i]} last={item.last} key={i} />
                ))}
              </TableHeaderWrapper>

              {orderListData
                .filter(e => e.active)
                .map((item, i) => (
                  <ListItemWrapper index={i} key={i}>
                    <TableContent itemData={item} width={widthArr[i]} />
                  </ListItemWrapper>
                ))} */}
            </PanelWrapper>
          </Collapse>
        </OrderWrapper>
      </HLayoutWrapper>
    </>
  )
}
