import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

// Redirects to longterm swap but only replace the pathname
export function RedirectPathToTermSwapOnly({ location }: RouteComponentProps) {
  return <Redirect to={{ ...location, pathname: '/instantswap' }} />
}

// Redirects from the /termswap/:outputCurrency path to the /termswap?outputCurrency=:outputCurrency format
export function RedirectToTermSwap(props: RouteComponentProps<{ outputCurrency: string }>) {
  const {
    location: { search },
    match: {
      params: { outputCurrency }
    }
  } = props

  return (
    <Redirect
      to={{
        ...props.location,
        pathname: '/termswap',
        search:
          search && search.length > 1
            ? `${search}&outputCurrency=${outputCurrency}`
            : `?outputCurrency=${outputCurrency}`
      }}
    />
  )
}
