import React from 'react'
import { Price, Currency, Fraction, JSBI } from '@uniswap/sdk'
import { useContext } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'
import { useActiveWeb3React } from '../../hooks'

interface TradePriceProps {
  price?: Price
  inputToken?: Currency | undefined
  outputToken?: Currency | undefined
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted, inputToken, outputToken }: TradePriceProps) {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  const checkMantleBase = chainId === 5000 || chainId === 5001

  // const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  let formattedPrice = showInverted ? price : price?.invert()

  let res = new Fraction(
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0)),
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(0))
  )

  if (formattedPrice) {
    // @ts-ignore
    formattedPrice.scalar = res
    // @ts-ignore
    formattedPrice = formattedPrice?.toSignificant(6)
  }

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${
        checkMantleBase
          ? outputToken?.symbol === 'ETH'
            ? 'MNT'
            : outputToken?.symbol
          : outputToken?.symbol || price?.quoteCurrency?.symbol
      } per ${
        checkMantleBase
          ? inputToken?.symbol === 'ETH'
            ? 'MNT'
            : inputToken?.symbol
          : inputToken?.symbol || price?.baseCurrency?.symbol
      }`
    : `${
        checkMantleBase
          ? inputToken?.symbol === 'ETH'
            ? 'MNT'
            : inputToken?.symbol
          : inputToken?.symbol || price?.baseCurrency?.symbol
      } per ${
        checkMantleBase
          ? outputToken?.symbol === 'ETH'
            ? 'MNT'
            : outputToken?.symbol
          : outputToken?.symbol || price?.quoteCurrency?.symbol
      }`

  return (
    <Text
      fontWeight={500}
      fontSize={14}
      color={theme.text2}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Repeat size={14} />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
